import React, { FunctionComponent } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import CenterModal from '../../../../networking/models/Center';
import {useTheme} from '../../../contexts/ThemeContext';


interface Props {
    center: any;
    onClick?: (center: CenterModal) => void;
}

const Center: FunctionComponent<Props> = ({ center, onClick }) => {
    const { theme } = useTheme();

    const onCenterClick = () => {
        if(!onClick) return;

        onClick(center);
    };

    return (
      <div className={`center center-list__grid center--${theme.modifier} center--${onClick ? 'with' : 'without'}-animations`} onClick={onCenterClick}>
          <span className={'center__text--grey center__name'}>{ center.name }</span>
          <span className={'center__text--grey'}>{ center.city }</span>
          <span className={'center__text--grey'}>{ center.contact_id ? center.contact?.first_name  + ' ' + center.contact?.last_name : '-' }</span>
          <span className={'center__text--grey'}>{ center.email }</span>
          <span className={'center__text--grey'}>{ center.phonenumber }</span>
          <span className={'center__text--grey'}>{ center.created_at }</span>

          { onClick &&
            <ArrowIcon className={'audit__icon icon-grey--filled'} onClick={onCenterClick} />
          }
      </div>
    );
};

export default Center;

