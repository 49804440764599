import {app} from 'buro-lib-ts';

import User from '../networking/models/User';
import {AppEvent} from './Event';

export const userHasRole = (user: User, role: string) => {
    return !!user?.roles.find(userRole => userRole.role === role);
};

export const Roles = {
    SUPER_ADMIN: 'superAdmin',
    CENTER_ADMIN: 'centerAdmin',
    AUDITOR: 'auditor',
    OPERATOR: 'operator',
    COACH: 'coach'
};

export const sessionHandler = () => {
    let timeout: any = null;
    const INTERVAL_DURATION: number = 1000 * 60 * 15;
    const WARNING_DURATION: number = 1000 * 60;

    let tokenExpiration = 0;

    const init = () => {
        setTimer();

        document.addEventListener('click', setTimer);
        document.addEventListener('visibilitychange', onDocumentVisibilityChange);
    };

    const setTimer = () => {
        clearTimeout(timeout);

        tokenExpiration = new Date().getTime() + INTERVAL_DURATION;
        timeout = setTimeout(onSessionTimeout, INTERVAL_DURATION);
    };

    const clearTimer = () => {
        clearTimeout(timeout);

        document.removeEventListener('click', setTimer);
        document.removeEventListener('visibilitychange', onDocumentVisibilityChange);
    };

    const onDocumentVisibilityChange = () => {
        if(document.hidden)
            return;

        if(new Date().getTime() > tokenExpiration) {
            clearTimer();
            onSessionTimeout();
        }
        if(new Date().getTime() > tokenExpiration - WARNING_DURATION) {
            onSessionTimeoutWarning()
        }
    };

    const onSessionTimeout = () => {
        app().emitEvent(AppEvent.SESSION_EXPIRED);
    };

    const onSessionTimeoutWarning = () => {
        app().emitEvent(AppEvent.SESSION_EXPIRED_WARNING);
    }

    return { init, clear: clearTimer };
};
