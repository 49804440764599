import React, {FunctionComponent} from 'react';

import UploadArea from './UploadArea';
import Button from '../../../layout/buttons/Button';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';

import CheckIcon from '../../../utils/CheckIcon';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    auditCallNumber: number;
    auditCallCount: number;
    files: FileData[];
    setFiles: (files: FileData[]) => void;
    onFileUpload: (file: FileData) => void;
    onFileDelete?: (file: FileData) => void;
    onAddClick: () => void;
    addDisabled: boolean;
    fileUploadAllowed: boolean;
    onNextClick: () => void;
    onPreviousClick: () => void;
    showAddButton: boolean;
    uploadsCompleted: boolean;
}

export interface FileData {
    file: File;
    progress: number;
    extension: string;
}

const UploadBox: FunctionComponent<Props> = (props) => {
    const {
        uploadsCompleted, showAddButton, onNextClick, onPreviousClick, auditCallNumber, auditCallCount, files,
        setFiles, onFileUpload, onFileDelete, onAddClick, addDisabled, fileUploadAllowed
    } = props;

    const { theme } = useTheme();

    const onFileDrop = (file: File, extension: string) => {
        if(hasFile(extension) || !fileUploadAllowed) return;

        onFileUpload({
            file: file,
            progress: 0,
            extension: extension
        });
    };

    const hasFile = (extension: string) => {
        for(const file of files) {
            if(file.extension === extension) {
                return true;
            }
        }

        return false;
    };

    const onUploadAreaDeleteClick = (item: number) => {
        const fileList: FileData[] = [ ...files ];
        const file = fileList[item];

        fileList.splice(item, 1);

        setFiles(fileList);

        onFileDelete && onFileDelete(file);
    };

    const ntsForm = () => {
        return files.findIndex(f => f.extension === 'pdf');
    };

    const audioFile = () => {
        return files.findIndex(f => f.extension !== 'pdf');
    };

    const canGoNext = () => {
        return (auditCallNumber < auditCallCount && uploadsCompleted);
    };

    const canGoPrevious = () => {
        return auditCallNumber > 1 && fileUploadAllowed;
    };

    const audioFileExtensions = ['mp3', 'wav'];
    const ntsFormExtensions = ['pdf'];

    return (
        <div className={`upload-box upload-box--${theme.modifier}`}>
                <div className={'upload-box__header'}>
                    { fileUploadAllowed &&
                        <span className={'upload-box__title'}>Gesprek { auditCallNumber } van de { auditCallCount }</span>
                    }

                    <div>
                        { canGoPrevious() &&
                            <ArrowIcon
                                className={'icon-grey upload-box__direction-icon upload-box__direction-icon--previous'}
                                onClick={onPreviousClick} />
                        }

                        { canGoNext() &&
                            <ArrowIcon
                                className={'icon-grey upload-box__direction-icon upload-box__direction-icon--next'}
                                onClick={onNextClick} />
                        }
                    </div>
                </div>


                <div className={'upload-box__content'}>
                    { fileUploadAllowed &&
                        <React.Fragment>
                            <div className={'upload-box__upload-container'}>
                                <span className={'upload-box__file-title'}>Audio bestand</span>

                                <UploadArea
                                    uploadAllowed={audioFile() === -1}
                                    onFileDrop={onFileDrop}
                                    allowedExtensions={audioFileExtensions}
                                    uploadProgress={files[audioFile()]?.progress ?? 0}
                                    uploadBarColor={'blue'}
                                    onDeleteClick={() => onUploadAreaDeleteClick(audioFile())} />
                            </div>

                            <span className={'upload-box__allowed-files-text'}>*Alleen { audioFileExtensions.map(i => i.toUpperCase()).join(', ') } wordt geaccepteerd.</span>

                            <div className={'upload-box__upload-container'}>
                                <span className={'upload-box__file-title'}>NTS formulier</span>

                                <UploadArea
                                    uploadAllowed={ntsForm() === -1}
                                    onFileDrop={onFileDrop}
                                    allowedExtensions={ntsFormExtensions}
                                    uploadProgress={files[ntsForm()]?.progress ?? 0}
                                    uploadBarColor={'green'}
                                    onDeleteClick={() => onUploadAreaDeleteClick(ntsForm())} />
                            </div>

                            <span className={'upload-box__allowed-files-text'}>*Alleen { ntsFormExtensions.map(i => i.toUpperCase()).join(', ') } wordt geaccepteerd.</span>

                            <div className={'upload-box__files'}>
                                { (!files.length) &&
                                    <span className={'upload-box__no-files-text'}>
                                        Sleep bestanden om ze up te loaden.
                                    </span>
                                }
                            </div>

                            { showAddButton &&
                                <div className={'upload-box__actions'}>
                                    <Button onClick={onAddClick} bold disabled={addDisabled}>Toevoegen</Button>
                                </div>
                            }
                        </React.Fragment>
                    }

                    {  !fileUploadAllowed &&
                        <CheckIcon className={'upload-box__check-icon'}/>
                    }
                </div>
        </div>
    );
};

export default UploadBox;
