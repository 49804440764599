import React, {FunctionComponent, useEffect} from 'react';
import {Route, useNavigate, Outlet, Navigate, useLocation, Routes} from 'react-router-dom';
import {
    BuroApp,
    route
} from 'buro-lib-ts';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import 'react-confirm-alert/src/react-confirm-alert.css';

import NavBar from './components/layout/navbar/NavBar';

import AuthContextProvider, {useAuth} from './components/contexts/AuthContext';
import SideBarContextProvider from './components/contexts/SideBarContext';
import ThemeContextProvider from './components/contexts/ThemeContext';

import Auth from 'components/layout/Auth';
import ToastContainer from 'components/layout/toast/ToastContainer';

import {routeMap} from './routing/Routes';
import {isDev} from './helpers/Env';
import DevTool from './components/utils/devtool/DevTool';
import Content from './components/layout/Content';
import ClientSidebarMenu from './components/client/layout/ClientSidebarMenu';
import {AnimatePresence} from 'framer-motion';
import AdminSidebarMenu from "./components/admin/layout/AdminSidebarMenu";
import AuditToolApp from './config/App';
import Page from 'components/layout/Page';

const queryClient = new QueryClient();

interface ProvidersProps {
    children: any;
}

const Providers: FunctionComponent<ProvidersProps> = ({children}) => {
    return (
        <ThemeContextProvider>
            <SideBarContextProvider>
                {children}
            </SideBarContextProvider>
        </ThemeContextProvider>
    );
};

const Admin: FunctionComponent = () => {

    const {isSuperAdmin} = useAuth();

    if (!isSuperAdmin()) {
        return <Navigate to={route('dashboard')}/>;
    }

    return (
        <React.Fragment>
            <AdminSidebarMenu/>

            <Content>
                <Outlet/>
            </Content>
            {/*<AdminContent />*/}
        </React.Fragment>
    );
};

const Client: FunctionComponent = () => {

    const {isSuperAdmin} = useAuth();

    if (isSuperAdmin()) {
        return (<Admin/>);
    }

    return (
        <React.Fragment>
            <ClientSidebarMenu/>

            <Content>
                <Outlet/>
            </Content>
            {/*<ClientContent />*/}
        </React.Fragment>
    );
};

const Routing: FunctionComponent = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const {isAuthenticated, isSuperAdmin} = useAuth();

    const RedirectToCorrectPage = () => {
        useEffect(() => {
            if (isSuperAdmin())
                navigate('/admin')
            else
                navigate('/dashboard');
        });
       
        return null;
    };

    if (!isAuthenticated())
        return <Auth/>;

    return (
        <>
            <NavBar/>

            <AnimatePresence mode={'wait'}>
                <Routes key={location.key} location={location}>
                    <Route path={'/admin'} element={<Admin/>}>
                        {routeMap.admin.map((route) =>
                            <Route key={route.path} path={route.path} element={<route.component/>}/>
                        )}
                    </Route>

                    <Route path={'/'} element={<Client/>}>
                        {routeMap.client.map(route =>
                            <Route key={route.path} path={route.path} element={<route.component/>}/>
                        )}

                        <Route path={''}
                               element={<RedirectToCorrectPage />}/>
                    </Route>
                    <Route path={'*'} element={<Page><Navigate to={'/dashboard'}/></Page>}/>
                </Routes>
            </AnimatePresence>
        </>
    );
};

const Layout: FunctionComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        app.onEvent('REDIRECT', (url: string) => {
            if (window.location.pathname === url) {
                return;
            }

            navigate(url);
        });
    }, []);

    return (
        <div className="layout">
            {
                isDev() && <DevTool/>
            }
            <React.StrictMode>
                <Routing/>
            </React.StrictMode>
            <ToastContainer/>
        </div>
    );
};

const app = new AuditToolApp();

const App: FunctionComponent = () => {

    return (
        <BuroApp app={app}>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <Providers>
                        <Layout/>
                    </Providers>
                </AuthContextProvider>
            </QueryClientProvider>
        </BuroApp>
    );
};

export default App;
