import React, {FunctionComponent} from 'react';
import SidebarMenuItem from '../../layout/sidebar/SidebarMenuItem';
import {ReactComponent as DashboardIcon} from '../../../assets/icons/dashboard-border.svg';
import {ReactComponent as DashboardIconActive} from '../../../assets/icons/dashboard.svg';
import {route} from 'buro-lib-ts';
import {ReactComponent as AuditIcon} from '../../../assets/icons/audits-border.svg';
import {ReactComponent as AuditIconActive} from '../../../assets/icons/audits.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/icon-user-border.svg';
import {ReactComponent as UserIconActive} from '../../../assets/icons/icon-user.svg';
import {ReactComponent as CenterIcon} from '../../../assets/icons/center-border.svg';
import {ReactComponent as CenterIconActive} from '../../../assets/icons/center.svg';
import {ReactComponent as StatisticsIcon} from '../../../assets/icons/statistics-border.svg';
import {ReactComponent as StatisticsIconActive} from '../../../assets/icons/statistics.svg';
import {ReactComponent as UsersIcon} from '../../../assets/icons/icon-users-border.svg';
import {ReactComponent as UsersIconActive} from '../../../assets/icons/icon-users.svg';
import {ReactComponent as ContactIcon} from '../../../assets/icons/contacts-border.svg';
import {ReactComponent as ContactIconActive} from '../../../assets/icons/contacts.svg';
import {ReactComponent as SettingsIcon} from '../../../assets/icons/settings-border.svg';
import {ReactComponent as SettingsIconActive} from '../../../assets/icons/settings.svg';
import {ReactComponent as BugIcon} from '../../../assets/icons/bug.svg';
import SidebarMenu from '../../layout/sidebar/SidebarMenu';
import {useAuth} from '../../contexts/AuthContext';

const ClientSidebarMenu: FunctionComponent = () => {
    const { isCenterAdmin, isAuditor, isOperator, isCoach } = useAuth();

    return (
        <SidebarMenu>
            <SidebarMenuItem exact={true} icon={DashboardIcon} activeIcon={DashboardIconActive} to={route('dashboard')}>Dashboard</SidebarMenuItem>

            { isOperator() &&
                <SidebarMenuItem icon={AuditIcon} activeIcon={AuditIconActive} to={route('audits-about-me')}>Over mij</SidebarMenuItem>
            }

            { isAuditor() &&
                <SidebarMenuItem icon={UserIcon} activeIcon={UserIconActive} to={route('audits-for-me')}>Voor mij</SidebarMenuItem>
            }

            {(isCoach() || isCenterAdmin()) &&
                <>
                    <SidebarMenuItem icon={CenterIcon} activeIcon={CenterIconActive} to={route('audits')} exact={true}>Meldkamer</SidebarMenuItem>
                    <SidebarMenuItem icon={StatisticsIcon} activeIcon={StatisticsIconActive} to={'/statistics'} exact={true}>Statistieken</SidebarMenuItem>

                    { (isCenterAdmin() || isCoach()) && <SidebarMenuItem icon={UsersIcon} activeIcon={UsersIconActive} to={'/users'}>Medewerkers</SidebarMenuItem> }

                    <SidebarMenuItem icon={ContactIcon} activeIcon={ContactIconActive} to={'/centers'}>Extern contact</SidebarMenuItem>

                    { isCenterAdmin() && <SidebarMenuItem icon={SettingsIcon} activeIcon={SettingsIconActive} to={'/settings'}>Instellingen</SidebarMenuItem> }
                </>
            }

            <SidebarMenuItem icon={BugIcon} activeIcon={BugIcon} to='/feedback'>Bug melden</SidebarMenuItem>
        </SidebarMenu>
    );
};

export default ClientSidebarMenu;
