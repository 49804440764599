import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../layout/buttons/Button';
import {useAuth} from '../../contexts/AuthContext';
import {useDevTool} from './DevTool';
import {DevCredentials} from '../../../helpers/DevTool';

interface Props {
    user: DevCredentials;
}

const DevUser: FunctionComponent<Props> = ({ user }) => {

    const auth = useAuth();
    const { toggleMenu } = useDevTool();
    const navigate = useNavigate();

    const loginUser = async () => {
        toggleMenu();

        await auth.login({ ...user, otp: '1' });

        navigate(user.redirect);
    };

    return (
        <div className={'dev-tool__user'}>
            <span className={'dev-tool__text'}>{ user.email }</span>
            <Button onClick={loginUser} disabled={auth.user?.email === user.email}>Login</Button>
        </div>
    );
};

export default DevUser;
