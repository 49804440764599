import React, {ChangeEvent, FunctionComponent, useState} from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    onSearch: (searchText: string) => void;
    value?: string;
    placeholder?: string;
}

const SearchInput: FunctionComponent<Props> = ({ onSearch, value, placeholder }) => {
  const { theme } = useTheme();

  const [inputValue, setValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);

      onSearch(e.target.value);
  };

  return (
      <div className={'search-input-container search-input--' + theme.modifier}>
          <span className={'search-input__icon'}><SearchIcon /></span>

          <input placeholder={placeholder ?? 'Zoeken...'} className={'search-input'} onChange={handleChange} value={value ?? inputValue    } />
      </div>
  );
};

export default SearchInput;

