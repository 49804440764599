import React, {FunctionComponent, Suspense} from "react";
import Page from "../../../layout/Page";
import Tabs from "../../../layout/tabs/Tabs";
import TabBar from "../../../layout/tabs/TabBar";
import Tab from "../../../layout/tabs/Tab";
import {TabContent} from "buro-lib-ts";
import AuditList from "../../partials/audits/AuditList";
import Pagination, {PaginationProps} from "../../../layout/pagination/Pagination";
import Audit from "../../../../networking/models/Audit";

interface Props {
    title: string
    audits: Audit[];
    total: number;
    paginationData: PaginationProps;
}

const MyAudits: FunctionComponent<Props> = ({title, audits, total, paginationData}) => {

    return (
        <Page className={'audits-page'}>
            <Suspense>
                <Tabs scrollable deps={[audits]}>
                    <TabBar>
                        <Tab>{`${title} (${total})`}</Tab>
                    </TabBar>

                    <TabContent>
                        <h2>Alle audits {title.toLowerCase()}</h2>

                        <AuditList audits={audits}/>

                        <Pagination
                            current={paginationData.current}
                            max={paginationData.max}
                            setPage={paginationData.setPage}/>
                    </TabContent>
                </Tabs>
            </Suspense>
        </Page>
    )
}

export default MyAudits