import React, { FunctionComponent, useState } from 'react';
import { TabContent } from 'buro-lib-ts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Page from 'components/layout/Page';
import Tabs from 'components/layout/tabs/Tabs';
import TabBar from 'components/layout/tabs/TabBar';
import TabBarNavigation from 'components/layout/tabs/TabBarNavigation';
import BackButton from 'components/layout/buttons/BackButton';
import { useUserIndexWithBlockedQueryConfig } from 'components/query/User/Query';
import UsersTab from 'components/client/pages/users/UsersTab';
import { useUserCollectionMutations } from 'components/query/User/Mutation';
import { success, warn } from 'helpers/Toast';
import User from 'networking/models/User';
import { logError } from 'components/utils/devtool/DevTool';
import { UserForm } from 'components/form/User';

const CenterAdmins: FunctionComponent = () => {
    const params = useParams();
    const centerId: any = params.id!;

    const navigate = useNavigate();

    const [userConfig] = useUserIndexWithBlockedQueryConfig(centerId, 'centerAdmin');
    const userQuery = useQuery(userConfig);

    const {
        createUser,
        updateUser,
        softDeleteUser,
        restoreUser
    } = useUserCollectionMutations(centerId);

    const [search, setSearch] = useState('');

    const onUserCreate = async (savedUser: UserForm, roles: string[]) => {
        createUser.mutateAsync({...savedUser, roles})
            .then(() => success("Gebruiker is opgeslagen."))
            .catch((e) => {
                logError(e);
                warn("Oeps, er is iets misgegaan!");
            });
    };

    const onUserUpdate = (user: User, data: UserForm, roles: string[]) => {
        updateUser.mutateAsync({id: user.id!, request: {...data, roles: user.roles.map((role) => role.role)}})
            .then(() => success("Gebruiker is bijgewerkt."))
            .catch((e) => {
                logError(e);
                warn("Oeps, er is iets misgegaan!");
            });
    };

    const onUserSoftDelete = async (user: User) => {
        softDeleteUser.mutateAsync(user.id!)
            .then(() => success("Gebruiker is geblokkeerd."))
            .catch((e: any) => {
                logError(e);
                warn("Oeps, er is iets misgegaan!");
            });
    };

    const onUserRestore = async (user: User) => {
        restoreUser.mutateAsync(user.id!)
            .then(() => success("Gebruiker is gedeblokkeerd."))
            .catch((e: any) => {
                logError(e);
                warn("Oeps, er is iets misgegaan!");
            });
    };

    const onBackClick = () => {
        navigate(`/admin/centers/${centerId}`);
    };

    return (
        <Page className='pb-1'>
            <Tabs noErrors scrollable>
                <TabBar>
                    <TabBarNavigation>
                        <div className={'flex flex--justify-between w-100 flex--align-center'}>
                            <div>
                                <Link to={'/admin'} className={'tab-navigation__breadcrumbs'}>Meldkameroverzicht / </Link>
                                <span className={'tab-navigation__breadcrumbs tab-navigation__breadcrumbs--active'}>Meldkameroverzicht / </span>
                                <span className={'tab-navigation__breadcrumbs tab-navigation__breadcrumbs--active'}>Beheerders</span>
                            </div>

                            <BackButton onClick={onBackClick} />
                        </div>
                    </TabBarNavigation>
                </TabBar>

                <TabContent>
                    <UsersTab
                        onSearch={(q, s) => setSearch(s)}
                        onUserCreate={onUserCreate}
                        onUserRestore={onUserRestore}
                        onUserSoftDelete={onUserSoftDelete}
                        onUserUpdate={onUserUpdate}
                        searchQuery={search}
                        tabId={1}
                        title='Beheerders'
                        users={
                            userQuery.data?.filter((user) => (`${user.first_name}${user.last_name}`)
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) >= 0) ?? []
                        } />
                </TabContent>
            </Tabs>
        </Page>
    );
};

export default CenterAdmins;