import React, {FunctionComponent} from 'react';
import {RouteProps, useLocation, useNavigate} from 'react-router-dom';
import Page from 'components/layout/Page';
import AuthRepository from '../../../../networking/repos/AuthRepository';
import {notify} from 'helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {useCentersSsoQueryConfig} from "../../../query/Center/Query";
import {useQuery} from "@tanstack/react-query";

interface Center {
    id: number;
    name: string;
    image_url: string;
}

const Landing: FunctionComponent<RouteProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [authRepository] = [
        new AuthRepository()
    ];

    const [centerSsoConfig] = useCentersSsoQueryConfig();
    const centerSso = useQuery(centerSsoConfig);

    const handleClick = async (id: number) => {

        const redirectTo = location.pathname;
        sessionStorage.setItem("redirectTo", redirectTo)

        try {
            const ssoRequest = await authRepository.getSsoRedirectUrl(id);
            if (ssoRequest.url) {
                window.location.href = ssoRequest.url;
            } else {
                return navigate(`/login`);
            }
        } catch (e) {
            return logError(e) && notify('Oeps, er is iets misgegaan.');
        }
    };

    return (
        <Page className="auth__page">
            <div className="auth__page__form">
                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">
                        Selecteer uw meldkamer
                    </h1>
                </div>
                <div className="auth__page__form__row">
                    <div className="auth__page__form__grid">
                        {centerSso.data && centerSso.data.map((center: Center) =>
                            <div key={center.id} className="auth__page__form__grid__item"
                                 onClick={() => handleClick(center.id)}>
                                <img src={center.image_url} alt={'Center'}/>
                                {center.name}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Landing
