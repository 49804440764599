import React, {FunctionComponent, useState, useEffect, FormEvent, ChangeEvent} from 'react';

import IconInput from '../../../partials/input/IconInput';

import Button from 'components/layout/buttons/Button';

// Repository
import AuthRepository from '../../../../../networking/repos/AuthRepository';

import { ReactComponent as KeyPassword } from '../../../../../assets/icons/key-password.svg';
import {logError} from '../../../../utils/devtool/DevTool';

interface Props {
  token: string;
  handle2FASubmit: () => void;
}

const AddGoogle2FA: FunctionComponent<Props> = ({ token, handle2FASubmit }) => {
  const [otp, setOtp] = useState<string>('');
  const [qr, setQr] = useState<string>('');
  const [error, setError] = useState<string>();

  const [authRepository] = [
      new AuthRepository()
  ];

  useEffect(() => {
    authRepository.get2FAQR(token)
        .then(res => setQr(res.base64))
        .catch(e => logError(e));
  }, [token, setQr])

  const handleChangeOtp= (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value.replace(/\D/g,'').substring(0, 6));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      await authRepository.enable2FA({otp, token});

      setError('');
      handle2FASubmit();
    } catch (e) {
      logError(e);
      setError('Ongeldige code');
    }
  };

  return (
    <div>
      <form className="auth__registar__form" onSubmit={handleSubmit}>
        <div className="auth__registar__form__qr">
          <img src={qr} title="Scan deze code met de Google Authenticator app." alt="Qr-code"/>
          <p className="auth__registar__form__qr__text heading5--black">
            Scan de QR-code via de Google Authenticator-app op uw smartphone.
            <br/>
            Vul de code uit de app hieronder in.
          </p>
          <div className="auth__registar__form__otp">
            <IconInput
              id="password-confirmation"
              value={otp}
              onChange={handleChangeOtp}
              icon={KeyPassword}
              label={true}
              labelName="Google Authenticator code"
            />
            <p className="auth__registar__form__otp__error">{error}</p>
          </div>
          <Button className="button">Voltooien</Button>
        </div>
      </form>
    </div>
  );
};

export default AddGoogle2FA;
