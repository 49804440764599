import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import Report from '../models/Report';

class ReportRepository extends ModelRepository<Report> {

    public constructor() {
        super('/report');
    }

    public getReport(auditId: number): Promise<Report> {
      return this.request<Report>(this.url(`/${auditId}`), RequestMethod.GET).send();
    }

}

export default ReportRepository;
