import React, { FunctionComponent } from 'react';

interface Props {
    children: any;
}

const TabBarHeader: FunctionComponent<Props> = ({ children }) => {
    return (
      <div className={'tab-navigation__bar--top'}>
          { children }
      </div>
    );
};

export default TabBarHeader;

