import React, { FunctionComponent } from 'react';

export enum LogType {
    INFO = 'info', ERROR = 'error'
}

export interface LogEntry {
    type: LogType;
    log: any;
    timestamp: string;
}

interface Props {
    entries: LogEntry[];
}

const getAttributeToken = (value: any) => {
    if(typeof value === 'string') {
        return "'";
    }

    return '';
};

const getLogAttributeString = (value: any) => {
    const strings: string[] = [];

    if(Array.isArray(value)) {
        strings.push(`[${value.map(v => getLogAttributeString(v)).join(', ')}]`);
    } else if(typeof value === 'object') {
        const entries = Object.entries(value).map(([key, value]) => {
            return `${key}: ${getLogAttributeString(value)}`;
        }).join(', ');

        strings.push(`{ ${entries} }`);
    } else {
        const token = getAttributeToken(value);

        strings.push(`${token}${value}${token}`);
    }

    return strings.join(', ');
};

const Log: FunctionComponent<Props> = ({ entries }) => {

    const getLogEntry = (e: LogEntry) => {
        const isObject = typeof e.log === 'object';

        const logLines = Object.entries(e.log).map(([key, value]) => (
            <span key={key}>{key}: {getLogAttributeString(value)}</span>
        ));

        return (
            <div className={'flex'}>
                <span className={'dev-tool__entry-title'}>{ e.timestamp }:</span>

                <div className={'flex-1 flex flex--column'}>
                    { isObject
                        ? logLines
                        : e.log
                    }
                </div>
            </div>
        );
    };

    return (
        <div className={'dev-tool__log'}>
            { entries.map((entry,i) => (
                <div className={`dev-tool__log-entry dev-tool__log-entry--${entry.type}`} key={i}>
                    { getLogEntry(entry) }
                </div>
            )) }
        </div>
    );
};

export default Log;
