import React, {useState, FormEvent, ChangeEvent, useEffect, FunctionComponent, useMemo} from 'react';
import { NavLink, useNavigate, RouteProps, useLocation } from 'react-router-dom';
import {input, route, useForm} from 'buro-lib-ts';
import Page from 'components/layout/Page';
import Input from 'components/client/partials/input/Input';
import IconInput from 'components/client/partials/input/IconInput';
import BackButton from '../../../layout/buttons/BackButton';
import Button from 'components/layout/buttons/Button';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg';
import { ReactComponent as Message } from '../../../../assets/icons/message.svg';
import { ReactComponent as KeyPassword } from '../../../../assets/icons/key-password.svg';
import { useAuth } from 'components/contexts/AuthContext';
import AuthRepository from '../../../../networking/repos/AuthRepository';
import User from '../../../../networking/models/User';
import {Roles, userHasRole} from '../../../../helpers/Auth';

class Credentials {
    region: string = "";
    email: string = "";
    password: string = "";
    remember: string = "";
}

interface OtpForm {
    otp: string;
}

// TODO: TEST form change
const Login: FunctionComponent<RouteProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { login } = useAuth();

    const [credentials, setCredentials] = useState<Credentials>(new Credentials());
    const [urlParams, setUrlParams] = useState<string>('');
    const [redirectLocation, setRedirectLocation] = useState<string>('/');
    const [loginError, setLoginError] = useState<string>('');

    const memo = useMemo(() => ({
        otp: input('')
    }), []);

    const [form, onFormChange] = useForm<OtpForm>(memo);
    const [showOtp, setShowOtp] = useState<boolean>(false);

    const [authRepository] = [
        new AuthRepository()
    ];

    useEffect(() => {
        const newUrlParams = new URLSearchParams();

        setRedirectLocation(sessionStorage.getItem("redirectTo") || "/");

        credentials.email.length > 0 && newUrlParams.append("email", credentials.email)
        credentials.region.length > 0 && newUrlParams.append("region", credentials.region)

        setUrlParams(newUrlParams.toString());
    }, [credentials.region, credentials.email, setRedirectLocation, setUrlParams]);

    const clearError = () => {
        setLoginError('');
    };

    const getRedirectLocation = (user: User) => {
        if(redirectLocation === '/' && userHasRole(user, Roles.SUPER_ADMIN)) {
            return route('admin-dashboard');
        }

        return redirectLocation;
    };

    const handleOtpLogin = async () => {
        if(form.values.otp === '')
            return setLoginError("Vul de code in.");

        const user: User = await login({
            'email': credentials.email,
            'password': credentials.password,
            'otp': form.values.otp
        });

        navigate(getRedirectLocation(user));
    };

    const handleEmailLogin = async () => {
        await authRepository.verifyCredentials(credentials);
        setShowOtp(true);
        clearError();
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if(credentials.email.length <= 0 || credentials.password.length <= 0)
            return setLoginError("Er zijn velden niet ingevuld.");

        try {
            if(showOtp) { // State: Showing the 2FA page.
                await handleOtpLogin();
            } else { // State: Showing the email & pass page
                await handleEmailLogin();
            }
        } catch (error: any) {
            setLoginError((error.message && error.code !== 422) ? error.message : 'Er is iets misgegaan! Heeft u alles juist ingevuld?');
        }
    }

    const handleChangeCredentials = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setCredentials({ ...credentials, [e.target.id]: e.target.value })
    }

    const handleChangeOtp = (value: string) => {
        //Max. 6 characters, only numbers.
        form.set({ otp: value.replace(/\D/g,'').substring(0, 6) });
    }

    const goBack = () => {
        setShowOtp(false);
        form.set({ otp: '' });
    }

    const goBackToLanding = () => {
        navigate('/');
    }

    return (
        <Page className={'auth__page auth__page'}>
            <form className="auth__page__form" onSubmit={handleSubmit}>
                <div className="auth__page__form__row auth__page__form__row__meldkamer">
                    <div className="auth__page__form__row__col" />
                </div>

                {
                    (showOtp ?
                        <React.Fragment>
                            <BackButton onClick={goBack} />
                        </React.Fragment> : 
                            <div className="auth__page__form__back" onClick={goBackToLanding}>
                                <Arrow />
                                <p>Terug</p>
                            </div>
                        )
                }

                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">Inloggen</h1>
                </div>

                {
                    (!showOtp) ? (
                        <React.Fragment>
                            <IconInput
                                id="email"
                                value={credentials.email}
                                onChange={handleChangeCredentials}
                                icon={Message}
                                label={true}
                                labelName="E-mail"
                                password={false}
                            />
    
                            <IconInput
                                id="password"
                                value={credentials.password}
                                onChange={handleChangeCredentials}
                                icon={KeyPassword}
                                label={true}
                                labelName="Wachtwoord"
                                password={true}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="auth__page__form__otp-text">Voer de code in die staat aangegeven in de Google Authenticator app.</div>

                            <Input 
                                id={'otp'}
                                form={form}
                                onChange={({ value }) => handleChangeOtp(value)}
                                text="Code"
                                onKeyPress={(e:React.KeyboardEvent) => {e.key === 'Enter' && handleSubmit(e); }}
                                ref={input => input && input.focus()}
                            />
                        </React.Fragment>
                    )
                }

                <p className={loginError ? 'auth__page__form__error' : 'auth__page__form__error auth__page__form__error--hidden'}>*{loginError}</p>

                <br />

                {
                    !showOtp && (
                        <div className="auth__page__form__row auth__page__form__troubleshooting">
                            <NavLink to={`/password-reset${urlParams && `?${urlParams}`}`}>Wachtwoord vergeten?</NavLink>
                        </div>
                    )
                }

                <Button className="button--inline">Inloggen</Button>
            </form>
        </Page>
    )
}

export default Login
