import React, {FunctionComponent, useState} from 'react';

import Page from 'components/layout/Page';
import SearchInput from 'components/client/partials/input/SearchInput';

import CenterList from '../../partials/centers/CenterList';
import {useTheme} from '../../../contexts/ThemeContext';
import {useCenterIndexQueryConfig} from '../../../query/Center/Query';
import {useQuery} from '@tanstack/react-query';

const Centers: FunctionComponent = () => {
    const {theme} = useTheme();

    const [centerConfig] = useCenterIndexQueryConfig();
    const centerQuery = useQuery(centerConfig);

    const [searchQuery, setSearchQuery] = useState<string>('');

    const getFilteredCenters = () => {
        const centers = centerQuery.data ?? [];

        if (searchQuery === '')
            return centers;

        return centers.filter((center: any) =>
            (center.name.toLowerCase()).search(searchQuery.toLowerCase()) !== -1);
    };

    const handleSearch = (query: string) => {
        const regex = /[^a-z-]/gi;
        const filteredQuery = query.replace(regex, '');
        setSearchQuery(filteredQuery);
    }

    return (
        <Page className={`centers-page centers-page--${theme.modifier}`}>
            <h2 className="centers-page__title">Externe contactpersonen</h2>
            <div className="users-page__search">
                <SearchInput placeholder={'Zoek meldkamer...'} onSearch={handleSearch} />
            </div>

            <CenterList centers={getFilteredCenters()}/>
        </Page>
    );
};

export default Centers;
