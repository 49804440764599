import React, { FunctionComponent } from 'react';

import CenterSettings from './CenterSettings';

interface Props {}

const Settings: FunctionComponent<Props> = () => {

    return (
        <CenterSettings />
    );
};

export default Settings;
