import React, {createContext, FunctionComponent, useCallback, useContext, useEffect, useState} from 'react';

import moment from 'moment';
import {app} from 'buro-lib-ts';
import {Dock} from 'react-dock';

import {DevCredentials, getCredentials} from '../../../helpers/DevTool';
import DevUsers from './DevUsers';
import DevToolSection from './DevToolSection';
import {useAuth} from '../../contexts/AuthContext';
import Log, {LogEntry, LogType} from './Log';
import {getEnvUrl, isDev} from '../../../helpers/Env';
import Button from '../../layout/buttons/Button';

interface DevToolContext {
    toggleMenu: () => void;
}

const Context = createContext<DevToolContext>({} as DevToolContext);

export const useDevTool = () => useContext(Context);

const log = (type: LogType, log: string) => isDev() && app().emitEvent('dev_log', {
    type, log,
    timestamp: moment().format('HH:mm')
});
export const logError = (e: any) => log(LogType.ERROR, e);
export const logInfo = (e: any) => log(LogType.INFO, e);

interface Props {}

const DevTool: FunctionComponent<Props> = () => {
    const { user } = useAuth();
    const [visible, setVisible] = useState<boolean>(false);
    const [credentials, setCredentials] = useState<DevCredentials[]>([]);
    const [logs, setLogs] = useState<LogEntry[]>([]);

    const toggleMenu = useCallback(() => setVisible(visible => !visible), [setVisible]);

    const onKeyUp = useCallback((e: KeyboardEvent) => {
        if(e.key === 'Escape')
            toggleMenu();
    }, [toggleMenu]);

    useEffect(() => {
        setCredentials(getCredentials());
    }, [setCredentials]);

    useEffect(() => {
        app().onEvent('dev_log', (log) => {
            setLogs(logs => [...logs, log]);
        });

        logInfo(`Repository url at: ${getEnvUrl()}`)

        document.addEventListener('keyup', onKeyUp);
    }, [setLogs, onKeyUp]);

    const clearLog = () => setLogs([]);

    return (
        <Dock position={'left'} isVisible={visible}>
            <Context.Provider value={{ toggleMenu }}>
                <div className={'dev-tool'}>
                    <div className={'flex flex--justify-between'}>
                        <h2>DevTool</h2>

                        { user &&
                            <span>Logged in as: { user.full_name }</span>
                        }
                    </div>

                    <DevToolSection title={'Auth'}>
                        { credentials.length
                            ? <DevUsers users={credentials} />
                            : <span>No users found in .env.local</span>
                        }
                    </DevToolSection>

                    <DevToolSection title={'Dev log'} right={<Button onClick={clearLog}>Clear log</Button>}>
                        <Log entries={logs} />
                    </DevToolSection>
                </div>
            </Context.Provider>
        </Dock>
    );
};

export default DevTool;
