import {app} from 'buro-lib-ts';

interface Toast {
    type: string;
    message: string;
}

export const toast = (toast: Toast | string) => {
    app().emitEvent('TOAST', toast);
};

export const notify = (message: string) => {
    toast({ type: 'message', message: message});
};

export const warn = (message: string) => {
    toast({ type: 'error', message: message});
};

export const success = (message: string) => {
    toast({type: 'success', message: message});
}
