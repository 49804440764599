import React, {useRef, useEffect, FunctionComponent} from 'react'

interface Props {
    total: number,
    finished: number
}

const ProgressCircle: FunctionComponent<Props> = ({ total, finished }) => {
    const circle = useRef<SVGCircleElement>(null);

    useEffect(() => {
        const percentage: number = Math.round((finished / total) * 100);
        const angle = 60 - (60 * percentage) / 100;

        if (circle.current === null) return;

        circle.current.style.strokeDashoffset = angle.toString();

        if (percentage === 0) {
            circle.current.style.strokeWidth = '0';
        } else {
            circle.current.style.strokeWidth = '3';
        }
    }, [finished]);

    return (
        <div className="progress-icon">
            {(total === finished) ? (
                <svg className="progress-icon--tick" viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                </svg>
            ) : (
                    <div className="progress-icon--circle">
                        <svg>
                            <circle cx="10" cy="10" r="10" />
                            <circle cx="10" cy="10" r="10" ref={circle} />
                        </svg>
                    </div>
                )}
        </div>
    )
}

export default ProgressCircle;
