import {RouteContainer, RouteType} from 'buro-lib-ts';

import Dashboard from '../components/client/pages/dashboard/Dashboard';
import Audits from '../components/client/pages/audits/Audits';
import Centers from '../components/client/pages/centers/Centers';
import Conversations from '../components/client/pages/conversations/Conversations';
import Report from '../components/client/pages/report/Report';
import Statistics from '../components/client/pages/statistics/Statistics';
import Users from '../components/client/pages/users/Users';
import Account from '../components/client/pages/account/Account';
import Form from '../components/client/pages/form/Form';
import Settings from '../components/client/pages/settings/Settings';
import AuditsAboutMe from '../components/client/pages/audits/AuditsAboutMe';
import AuditsForMe from '../components/client/pages/audits/AuditsForMe';
import ConversationForm from 'components/client/pages/conversations/form/ConversationForm';
import UserAudits from '../components/client/pages/users/UserAudits/UserAudits';
import ReportFeedback from 'components/client/pages/user_feedback/ReportFeedback';

const routeContainer: RouteContainer = new RouteContainer();

routeContainer.groupType(RouteType.PRIVATE, () => {
    routeContainer.add('/dashboard', Dashboard, 'dashboard');
    routeContainer.add('/audits', Audits, 'audits');

    routeContainer.add('/centers', Centers, 'centers');
    routeContainer.add('/report/:auditId', Report, 'report');
    routeContainer.add('/statistics', Statistics, 'statistics');
    routeContainer.add('/users', Users, 'users');
    routeContainer.add('/users/:id/audits', UserAudits, 'user-audits');
    routeContainer.add('/account', Account, 'account');
    routeContainer.add('/forms/:formId', Form, 'form');
    routeContainer.add('/settings', Settings, 'settings');
});

routeContainer.add('/aboutme', AuditsAboutMe, 'audits-about-me');
routeContainer.add('/tasks', AuditsForMe, 'audits-for-me');

routeContainer.add('/feedback', ReportFeedback, 'report-feedback');

routeContainer.group('/audits', () => {
    routeContainer.add('/:audit', Conversations, 'audit-detail');
    routeContainer.add('/:auditId/conversations/:conversationId', ConversationForm, 'conversation-detail');
}, RouteType.PRIVATE);

export default routeContainer.getRoutes();
