import { TabContent } from 'buro-lib-ts';
import UserFeedbackList from 'components/admin/partials/user_feedback/UserFeedbackList';
import { useUserFeedbackIndexQuery } from 'components/admin/query/UserFeedback';
import Select from 'components/client/partials/input/select/Select';
import Page from 'components/layout/Page';
import Pagination from 'components/layout/pagination/Pagination';
import Tab from 'components/layout/tabs/Tab';
import TabBar from 'components/layout/tabs/TabBar';
import Tabs from 'components/layout/tabs/Tabs';
import { UserFeedbackIndexRequest } from 'networking/requests/UserFeedback';
import React, { FunctionComponent, useEffect, useState } from 'react';

const UserFeedback: FunctionComponent = () => {

    const [filters, setFilters] = useState<UserFeedbackIndexRequest>({
        status: '',
        page: 1
    });

    const userFeedbackQuery = useUserFeedbackIndexQuery(filters);

    const filterOptions = [
        { id: 'open', label: 'Open' },
        { id: 'completed', label: 'Afgehandeld' },
        { id: 'all', label: 'Alles' },
    ];

    useEffect(() => {
        setFilters(f => ({
            ...f,
            page: 1
        }));
    }, [filters.status]);

    return (
        <Page scrollable>
            <Tabs noErrors>

                <TabContent>
                    <h2 className='page__title'>Bug meldingen</h2>

                    <div className='mb-1'>
                        <Select 
                            options={filterOptions} 
                            placeholder='Selecteer een status'
                            renderOption={o => o.label} 
                            onSelect={(o) => setFilters(f => ({...f, status: o.id}))} />
                    </div>

                    { userFeedbackQuery.data && <>
                        <UserFeedbackList feedback={userFeedbackQuery.data?.data ?? []} />

                        <Pagination 
                            current={userFeedbackQuery.data.currentPage}
                            max={userFeedbackQuery.data.lastPage} 
                            setPage={(page) => setFilters(f => ({...f, page}))} />
                    </>}
                </TabContent>
            </Tabs>
        </Page>
    );
};

export default UserFeedback;