import React, { FunctionComponent, useState } from 'react';
import { NavLink } from "react-router-dom";

interface Props {
    children: any;
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>,
    activeIcon: FunctionComponent<React.SVGProps<SVGSVGElement>>,
    to: string;
    exact?: boolean;
}

const SidebarMenuItem: FunctionComponent<Props> = ({ icon, activeIcon, to, children, exact }) => {
    const [active, setActive] = useState<boolean>(false);

    const Icon = active ? activeIcon : icon;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
    };

    const isActive = (match: any): boolean => {
        setTimeout(() => {
            setActive(match != null);
        }, 0);

        return match;
    };

    const exactLink = exact ?? false;

    // TODO: Check exact
    return (
        <NavLink to={to} className={({ isActive }) => `sidebar-menu__item ${isActive ? 'sidebar-menu__item--active' : ''}`} onClick={handleClick} end={exact}>
            <div className={'sidebar-menu__icon'}>
                <Icon className={'sidebar-menu__icon icon-white icon-white--filled'} />
            </div>

            <span className={'sidebar-menu__label'}>{ children }</span>
        </NavLink>
    );
};

export default SidebarMenuItem;
