import React, {
    createContext,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import {app, RequestError, route} from 'buro-lib-ts';

import Credentials from "../../networking/models/Credentials";
import User from "../../networking/models/User";
import TokenService from '../../networking/TokenService';
import AuthRepository from '../../networking/repos/AuthRepository';
import {warn} from '../../helpers/Toast';
import {userHasRole, Roles, sessionHandler} from '../../helpers/Auth';
import Loader from '../client/pages/Loader';
import UserGuides from '../../networking/models/UserGuides';
import {logError} from '../utils/devtool/DevTool';
import {AppEvent} from '../../helpers/Event';
import Guide from '../../networking/models/UserGuides';

interface AuthContext {
    isAuthenticated: () => boolean;
    isCenterAdmin: () => boolean;
    isCurrentUser: (userId: number | undefined) => boolean;
    isSuperAdmin: () => boolean;
    isAuditor: () => boolean;
    isOperator: () => boolean;
    isCoach: () => boolean;
    isOperatorOnly: () => boolean;
    login: (credentials: Credentials) => Promise<User>;
    loginWithoutCredentials: (token: string) => Promise<void | RequestError>;
    logout: () => void;
    user?: User;
    retrieveGuides: (guideName: string) => Promise<Guide>;
}

export const AuthContext = createContext({} as AuthContext);

export const useAuth = () => useContext(AuthContext);

interface Props {
    children: any;
}

const AuthContextProvider: FunctionComponent<Props> = ({ children }) => {
    const navigate = useNavigate();

    const [authRepository] = [
        new AuthRepository()
    ];

    const [loaded, setLoaded] = useState<boolean>(false);
    const [user, setUser] = useState<User | undefined>();
    const [remainingLoginTime, setRemainingLoginTime] = useState<number>(1000 * 60 * 15);

    const updateRemainingTime = (remainingTime : number) => {
        setRemainingLoginTime(remainingTime);
    }

    const retrieveGuides = async (guideName: string) => {
        return await authRepository.getGuides(guideName);
    }

    const retrieveUser = useCallback(async () => {
        const { user } = await authRepository.getCurrentUser();
        setUser(user);
    }, [setUser]);

    const setupAuth = useCallback(async () => {
        if(!TokenService.isAuthenticated()) {
            return;
        }

        try {
            await Promise.all([retrieveUser()]);
        } catch (error) {
            setTimeout(() => warn('Sessie is verlopen!'), 500);
        }
    }, [retrieveUser]);

    const setAuthToken = useCallback((token: string) => {
        TokenService.setToken(token);
    }, []);

    const clearUser = useCallback(() => {
        TokenService.removeToken();
        setUser(undefined);
    }, [setUser]);

    const logout = useCallback(() => {
        clearUser();
        navigate(route('landing'));
    }, [clearUser]);

    const onSessionTimeout = useCallback(() => {
        logout();
        warn('Sessie is verlopen!');
    }, [logout]);

    const onSessionTimeoutWarning = () => {
        warn('Sessie verloopt bijna! Klik om dit te voorkomen.');
    }

    useEffect(() => {
        app().onEvent(AppEvent.SESSION_EXPIRED, onSessionTimeout);

        return () => {
            app().removeEvent(AppEvent.SESSION_EXPIRED, onSessionTimeout);
        };
    }, [onSessionTimeout]);

    useEffect(() => {
        app().onEvent(AppEvent.SESSION_EXPIRED_WARNING, onSessionTimeoutWarning);

        return () => {
            app().removeEvent(AppEvent.SESSION_EXPIRED_WARNING, onSessionTimeoutWarning);
        };
    }, [onSessionTimeoutWarning]);

    useEffect(() => {
        const handler = sessionHandler();

        if(user) {
            handler.init();
        } else {
            handler.clear();
        }

        return () => {
            handler.clear();
        };
    }, [user, sessionHandler]);

    useEffect(() => {
        setupAuth().then(() => setLoaded(true));
    }, [setupAuth, setLoaded]);

    const login = async (credentials: Credentials): Promise<User> => {
        const loginResponse = await authRepository.loginUser(credentials)

        setAuthToken(loginResponse.token.original.access_token);
        setUser(loginResponse.user);

        return loginResponse.user;
    };

    const loginWithoutCredentials = useCallback(async (token: string): Promise<void | RequestError> => {
        try {
            setAuthToken(token);

            const currentUserResponse = await authRepository.getCurrentUser();
            setUser(currentUserResponse.user);
        } catch (requestError: any) {
            logError(requestError);
            return requestError;
        }
    }, [setAuthToken, authRepository, setUser]);

    const isCurrentUser = (userId: number | undefined) => {
        return user?.id === userId;
    }

    const isAuthenticated = () => {
        return typeof user !== 'undefined';
    };

    const hasRole = useCallback((role: string, onlyRole: boolean = false) => {
        if(onlyRole && user!.roles.length > 1) {
            return false;
        }

        return userHasRole(user!, role);
    }, [user]);

    const isCenterAdmin = useCallback(() => {
        return hasRole(Roles.CENTER_ADMIN);
    }, [hasRole]);

    const isSuperAdmin = useCallback(() => {
        return hasRole(Roles.SUPER_ADMIN);
    }, [hasRole]);

    const isAuditor = useCallback(() => {
        return hasRole(Roles.AUDITOR);
    }, [hasRole]);

    const isOperator = useCallback(() => {
        return hasRole(Roles.OPERATOR);
    }, [hasRole]);

    const isCoach = useCallback(() => {
        return hasRole(Roles.COACH);
    }, [hasRole]);

    const isOperatorOnly = useCallback(() => {
        return hasRole('operator', true);
    }, [hasRole]);

    if(!loaded) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isCenterAdmin,
                isCurrentUser,
                isSuperAdmin,
                isAuditor,
                isOperator,
                isOperatorOnly,
                isCoach,
                login,
                loginWithoutCredentials,
                logout,
                user,
                retrieveGuides,
            }}>
                { children }
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
