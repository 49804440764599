import React, {FunctionComponent} from 'react';

import ReportModel from '../../../../networking/models/Report';

interface Props {
    report: ReportModel;
}

const ReportContent: FunctionComponent<Props> = ({ report }) => {

    // TODO: Add if not found:
    // <React.Fragment>
    //     <p>Oeps! Dit rapport is niet gevonden, of u heeft er geen toegang toe!</p>
    // </React.Fragment>

    return (
        <div className="report-page report-page__content">
          <iframe title='Rapport' src={`data:application/pdf;base64,${report.file}`} />
        </div>
    );
};

export default ReportContent;
