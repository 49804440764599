import React, {FunctionComponent, useState} from 'react';
import {useQuery} from '@tanstack/react-query';

import User from '../../../../networking/models/User';
import {success, warn} from '../../../../helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {UserForm} from '../../../form/User';
import {useSuperAdminIndexQueryConfig, useSuperAdminMutations} from '../../query/SuperAdmin';
import UserTabs from "../../../client/partials/users/UserTabs";

interface TabState {
    searchQuery: string;
    title: string;
    filter?: (value: User, index: number, array: User[]) => boolean;
}

const SuperAdmins: FunctionComponent = () => {

    const {
        createAdmin,
        updateAdmin,
        softDeleteAdmin,
        restoreAdmin
    } = useSuperAdminMutations();

    const [superAdminConfig] = useSuperAdminIndexQueryConfig();
    const superAdminQuery = useQuery(superAdminConfig);

    const [tabs, setTabs] = useState<TabState[]>([
        {searchQuery: '', title: 'Alle gebruikers', filter: (user: User) => (!user.blocked)},
        {searchQuery: '', title: 'Geblokkeerde Gebruikers', filter: (user: User) => (user.blocked)},
    ]);

    const onUserCreate = async (user: UserForm) => {
        createAdmin.mutateAsync({...user, roles: ['superAdmin']})
            .catch(logError);
    };

    const onUserUpdate = async (user: User, data: UserForm) => {
        updateAdmin
            .mutateAsync({id: user.id!, request: {...data, roles: ['superAdmin']}})
            .catch(logError);
    };

    const onUserSoftDelete = async (user: User) => {
        softDeleteAdmin.mutateAsync(user.id!).then(() => {
            success('Gebruiker is verwijderd.');
        }).catch((error) => {
            logError(error);
            warn('Oeps, er is iets misgegaan!');
        });
    };

    const onUserRestore = async (user: User) => {
        restoreAdmin.mutateAsync(user.id!).then(() => {
            success('Gebruiker is gedeblokkeerd.');
        }).catch((error) => {
            logError(error);
            warn('Oeps, er is iets misgegaan!');
        });
    }

    return (
        <UserTabs tabs={tabs} setTabs={setTabs} onUserCreate={onUserCreate} onUserUpdate={onUserUpdate}
                  onUserSoftDelete={onUserSoftDelete} onUserRestore={onUserRestore} users={superAdminQuery.data ?? []}/>
    );
};

export default SuperAdmins;
