import React, {FunctionComponent} from 'react';
import {FormAccessor, OnFormChangeCallback, TabContentProps} from 'buro-lib-ts';

import TabContentLeft from 'components/client/pages/conversations/form/TabContentLeft';
import TabContentRight from 'components/client/pages/conversations/form/TabContentRight';

import Box from 'components/client/partials/box/Box';
import BoxList from 'components/client/partials/box/BoxList';
import BoxHeader from 'components/client/partials/box/BoxHeader';
import BoxShortcut from 'components/client/partials/box/BoxShortcut';
import BoxContent from 'components/client/partials/box/BoxContent';

import Input from 'components/client/partials/input/Input';
import Button from 'components/layout/buttons/Button';
import Select, {SelectOption} from 'components/client/partials/input/select/Select';
import CircleLoader from 'components/client/partials/loading/CircleLoader';

import { EntranceComplaints } from 'networking/repos/FormRepository';

// TODO: TEST ALL USES THORUIGLY check all if(!formData.values.patientAge and replace with !== ''
export interface FormData {
    urgencyCodeOperator: string;
    urgencyCodeAuditor: string;
    entranceComplaintOperator: string;
    entranceComplaintOpt1Operator: string;
    entranceComplaintOpt2Operator: string;
    entranceComplaintOpt3Operator: string;
    entranceComplaintAuditor: string;
    entranceComplaintOpt1Auditor: string;
    entranceComplaintOpt2Auditor: string;
    entranceComplaintOpt3Auditor: string;
}

interface Props extends TabContentProps {
    formData: FormAccessor<FormData>;
    onFormDataChange: OnFormChangeCallback<FormData>;
    disabled: boolean,
    ntsUrl?: string
    canGoNext: boolean;
    complaints: EntranceComplaints;
}

const FormTab: FunctionComponent<Props> = ({ complaints, formData, onFormDataChange, disabled, ntsUrl }) => {

    const onSelectChange = (field: string) => {
        const id = field as keyof FormData;

        return (value: any) => {
            onFormDataChange({ id, value });
        };
    };

    const onSearch = (value: string, options: SelectOption[]) => {
        return options.filter((o) => o.value.toLowerCase().includes(value));
    };

    if(!complaints) {
        return (
            <div className={'flex flex--justify-center'}>
                <CircleLoader />
            </div>
        );
    }

    return (
        <BoxList>
            <TabContentLeft>
                <Box>
                    <BoxHeader>
                        Formulier
                    </BoxHeader>

                    <BoxContent emptyText={'Geen snelkoppelingen beschikbaar'}>
                        <BoxShortcut desc={'Gegevens gesprek'} index={0}/>
                    </BoxContent>
                </Box>

                <React.Fragment>
                    <a href={ntsUrl} target={'_blank'}>
                        <Button disabled={!ntsUrl}>
                            NTS Formulier
                        </Button>
                    </a>
                </React.Fragment>
            </TabContentLeft>

            <TabContentRight scrollable={true}>
                <Box>
                    <BoxHeader>
                        1. Gegevens gesprek
                    </BoxHeader>

                    <BoxContent>
                        <div className={'conversation-form__info'}>
                            <div className={'conversation-form__info-block'}>
                                <div className={'flex flex--align-center'}>
                                    <span>Urgentiecode triagist</span>
                                </div>

                                <Input
                                    id={'urgencyCodeOperator'}
                                    text={'Urgentiecode triagist'}
                                    size={'big'}
                                    form={formData}
                                    onChange={onFormDataChange}
                                    disabled={disabled}/>
                            </div>

                            <div className={'conversation-form__info-block'}>
                                <div className={'flex flex--align-center'}>
                                    <span>Urgentiecode Auditor</span>
                                </div>

                                <Input
                                    id={'urgencyCodeAuditor'}
                                    text={'Urgentiecode Auditor'}
                                    size={'big'}
                                    form={formData}
                                    onChange={onFormDataChange}
                                    disabled={disabled}/>
                            </div>

                            <div className={'conversation-form__info-block'}>
                                <div>
                                    <span>Ingangsklacht triagist</span>
                                </div>

                                <div className={'conversation-form__spaced-block'}>
                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.operator}
                                        placeholder={'Kies een ingangsklacht'}
                                        initialIndex={complaints.operator.findIndex(o => o === formData.values.entranceComplaintOperator)}
                                        onSelect={onSelectChange('entranceComplaintOperator')} />

                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.operator}
                                        placeholder={'Kies een ingangsklacht (optioneel)'}
                                        initialIndex={complaints.operator.findIndex(o => o === formData.values.entranceComplaintOpt1Operator)}
                                        onSelect={onSelectChange('entranceComplaintOpt1Operator')} />

                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.operator}
                                        placeholder={'Kies een ingangsklacht (optioneel)'}
                                        initialIndex={complaints.operator.findIndex(o => o === formData.values.entranceComplaintOpt2Operator)}
                                        onSelect={onSelectChange('entranceComplaintOpt2Operator')} />

                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.operator}
                                        placeholder={'Kies een ingangsklacht (optioneel)'}
                                        initialIndex={complaints.operator.findIndex(o => o === formData.values.entranceComplaintOpt3Operator)}
                                        onSelect={onSelectChange('entranceComplaintOpt3Operator')} />
                                </div>
                            </div>

                            <div className={'conversation-form__info-block'}>
                                <div>
                                    <span>Ingangsklacht Auditor</span>
                                </div>

                                <div className={'conversation-form__spaced-block'}>
                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.auditor}
                                        placeholder={'Kies een ingangsklacht'}
                                        initialIndex={complaints.auditor.findIndex(o => o === formData.values.entranceComplaintAuditor)}
                                        onSelect={onSelectChange('entranceComplaintAuditor')} />

                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.auditor}
                                        placeholder={'Kies een ingangsklacht (optioneel)'}
                                        initialIndex={complaints.auditor.findIndex(o => o === formData.values.entranceComplaintOpt1Auditor)}
                                        onSelect={onSelectChange('entranceComplaintOpt1Auditor')} />

                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.auditor}
                                        placeholder={'Kies een ingangsklacht (optioneel)'}
                                        initialIndex={complaints.auditor.findIndex(o => o === formData.values.entranceComplaintOpt2Auditor)}
                                        onSelect={onSelectChange('entranceComplaintOpt2Auditor')} />

                                    <Select
                                        disabled={disabled}
                                        onSearch={onSearch}
                                        options={complaints.auditor}
                                        placeholder={'Kies een ingangsklacht (optioneel)'}
                                        initialIndex={complaints.auditor.findIndex(o => o === formData.values.entranceComplaintOpt3Auditor)}
                                        onSelect={onSelectChange('entranceComplaintOpt3Auditor')} />
                                </div>
                            </div>
                        </div>
                    </BoxContent>
                </Box>
            </TabContentRight>
        </BoxList>
    );
};

export default FormTab;
