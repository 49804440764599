import React, {FunctionComponent, SVGProps} from 'react';
import {ClassBuilder} from 'buro-lib-ts';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    focused?: boolean;
    text?: string;
    error?: string;
    className?: string;
    icon?: string | FunctionComponent<SVGProps<SVGSVGElement>>;
    children: any;
}

const InputContainer: FunctionComponent<Props> = ({ focused,text, error, icon, className, children }) => {
    const { theme } = useTheme();

    const getClassName = (): string => {
        return ClassBuilder.createClass(`input default-input default-input--${theme.modifier}`)
            .addIf('default-input--focused', focused)
            .addIf(className, className)
            .addIf(error, 'default-input--error')
            .build();
    };

    const getIcon = () => {
        if(typeof icon === 'function') {
            const Icon = icon;

            return <Icon className={`${iconClassName}`} />
        }

        return <img src={icon} alt="" className={`${iconClassName}`}/>
    };

    const iconClassName = 'icon-grey icon-grey--filled default-input__icon default-input__icon--left';

    return (
        <div className={getClassName()}>
            { (text) &&
                <header className={'input__header default-input__header'}>
                    <span>{ text }</span>
                </header>
            }

            <div className={'default-input__main'}>
                { icon &&
                    getIcon()
                }

                { children }
            </div>

            { error &&
                <p className={'default-input__error'}>{ error }</p>
            }
        </div>
    );
};

export default InputContainer;
