import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {TabContent, useForm, input} from 'buro-lib-ts';

import Button from '../../../../layout/buttons/Button';

import Tabs from 'components/layout/tabs/Tabs';
import TabBar from 'components/layout/tabs/TabBar';
import TabBarNavigation from 'components/layout/tabs/TabBarNavigation';
import AudioPlayer from 'components/client/partials/audioplayer/AudioPlayer';

import Tab from 'components/client/partials/conversations/form/Tab';

import FormTab, {FormData} from './tabs/FormTab';
import ProtocolTab, {CommentForm} from './tabs/ProtocolTab';
import Question from '../../../../../networking/models/Question';
import Score from '../../../../../networking/models/Score';

import FormPart from '../../../../../networking/models/FormPart';

import AuditCall from '../../../../../networking/models/AuditCall';

import {useAuth} from '../../../../contexts/AuthContext';
import BackButton from "../../../../layout/buttons/BackButton";
import { EntranceComplaints } from '../../../../../networking/repos/FormRepository';
import {logError} from '../../../../utils/devtool/DevTool';
import Audit from '../../../../../networking/models/Audit';
import {
    useAuditCallAnswerQuestionMutation,
    useAuditCallFinishMutation, useAuditCallUnlockMutation, useAuditCallUpdateCommentMutation,
    useAuditCallUpdateEntranceDataMutation
} from '../../../../query/AuditCall/Mutation';

interface Props {
    audit: Audit;
    auditCall: AuditCall;
    scores: Score[];
    entranceComplaints: EntranceComplaints;
    ntsFormUrl: string;
    audioFileBuffer?: ArrayBuffer;
}

// TODO: TEST EVERYHING, LIKE FORM
const ConversationFormContent: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();

    const params = useParams();

    const auditId = +params.auditId!;
    const conversationId = +params.conversationId!;

    const {user, isAuditor, isOperatorOnly} = useAuth();

    const [parts, setParts] = useState<FormPart[]>([]);
    const [auditCall, setAuditCall] = useState<AuditCall>(props.auditCall);
    const [disabled, setDisabled] = useState(false);

    const [commentForm, onCommentChange] = useForm<CommentForm>({
        comment: input(props.auditCall.comment || ''),
    });

    const [activeTab, setActiveTab] = useState<number>(0);

    const [form, onFormChange] = useForm<FormData>({
        urgencyCodeOperator: input(''),
        urgencyCodeAuditor: input(''),
        entranceComplaintOperator: input(''),
        entranceComplaintOpt1Operator: input(''),
        entranceComplaintOpt2Operator: input(''),
        entranceComplaintOpt3Operator: input(''),
        entranceComplaintAuditor: input(''),
        entranceComplaintOpt1Auditor: input(''),
        entranceComplaintOpt2Auditor: input(''),
        entranceComplaintOpt3Auditor: input(''),
    });

    const auditCallAnswerQuestionMutation = useAuditCallAnswerQuestionMutation(props.audit.id!, props.auditCall.id!);
    const auditCallUpdateEntranceDataMutation = useAuditCallUpdateEntranceDataMutation(props.audit.id!);
    const auditCallFinishMutation = useAuditCallFinishMutation(props.audit.id!, props.auditCall.id!);
    const auditCallUnlockMutation = useAuditCallUnlockMutation(props.audit.id!, props.auditCall.id!);
    const auditCallUpdateCommentMutation = useAuditCallUpdateCommentMutation(props.audit.id!);

    // TODO: Remove use query
    const retrieveAuditAndCall = useCallback(async () => {
        setParts(auditCall.form_parts!);

        form.set({
            urgencyCodeOperator: auditCall.urgency_code_operator || '',
            urgencyCodeAuditor: auditCall.urgency_code_auditor || '',
            entranceComplaintOperator: auditCall.entrance_complaint_operator,
            entranceComplaintOpt1Operator: auditCall.entrance_complaint_opt_1_operator,
            entranceComplaintOpt2Operator: auditCall.entrance_complaint_opt_2_operator,
            entranceComplaintOpt3Operator: auditCall.entrance_complaint_opt_3_operator,
            entranceComplaintAuditor: auditCall.entrance_complaint_auditor,
            entranceComplaintOpt1Auditor: auditCall.entrance_complaint_opt_1_auditor,
            entranceComplaintOpt2Auditor: auditCall.entrance_complaint_opt_2_auditor,
            entranceComplaintOpt3Auditor: auditCall.entrance_complaint_opt_3_auditor,
        });

        setDisabled(!isAuditor() || user?.id !== props.audit?.auditor_id || props.audit.completed ||
            (auditCall?.completed_at !== null && auditCall?.completed_at !== undefined)
        );
    }, [auditId, conversationId, setAuditCall, setParts, setDisabled, isAuditor, user]);

    useEffect(() => {
        retrieveAuditAndCall().catch(logError);

        // return () => {
        //     window.URL.revokeObjectURL(ntsUrl!);
        // };
    }, [retrieveAuditAndCall, auditId, conversationId]);

    const questionsAreAnswered = () => {
        for (let part of parts) {
            if (!part.questions) continue;

            for (let question of part.questions) {
                if (question.answer == undefined) {
                    return false;
                }
            }
        }

        return true;
    };

    const finishButtonDisabled = () => {
        return !questionsAreAnswered() || !formDataIsFilledIn();
    };

    const onQuestionAnswerChange = (question: Question, answer: number) => {
        auditCallAnswerQuestionMutation.mutate({
            question,
            answer
        }, {
            onError: logError
        });
    };

    const onUpdateComment = () => {
        auditCallUpdateCommentMutation.mutate({...auditCall, comment: commentForm.values.comment}, {
            onError: logError
        });
    }

    const finish = async () => {
        if (!auditCall) return;

        const newAuditCall: AuditCall = {
            ...auditCall,
            urgency_code_operator: form.values.urgencyCodeOperator,
            urgency_code_auditor: form.values.urgencyCodeAuditor,
            entrance_complaint_operator: form.values.entranceComplaintOperator,
            entrance_complaint_opt_1_operator: form.values.entranceComplaintOpt1Operator,
            entrance_complaint_opt_2_operator: form.values.entranceComplaintOpt2Operator,
            entrance_complaint_opt_3_operator: form.values.entranceComplaintOpt3Operator,
            entrance_complaint_auditor: form.values.entranceComplaintAuditor,
            entrance_complaint_opt_1_auditor: form.values.entranceComplaintOpt1Auditor,
            entrance_complaint_opt_2_auditor: form.values.entranceComplaintOpt2Auditor,
            entrance_complaint_opt_3_auditor: form.values.entranceComplaintOpt3Auditor,
        } as AuditCall;

        if (!formIsFinished()) {
            await auditCallUpdateEntranceDataMutation.mutateAsync({...newAuditCall, comment: commentForm.values.comment}, {
                onError: logError
            });

            auditCallFinishMutation.mutate();

            navigate(-1);
        } else {
            //when form is already finished open form
            unlockForm();
        }

        setAuditCall(newAuditCall);
    };

    const userIsNotAuditor = () => {
        return user?.id !== props.audit?.auditor_id
    }

    const unlockForm = () => {
        const newAuditCall: AuditCall = {
            ...auditCall,
            completed_at: auditCall?.completed_at ? null : ''
        } as AuditCall;

        setAuditCall(newAuditCall);
        auditCallUnlockMutation.mutate(undefined, {
            onError: logError
        });

        setDisabled(false);
    }

    const formIsFinished = () => {
        return auditCall?.completed_at !== null && auditCall?.completed_at !== undefined;
    };

    const getFinishButtonText = () => {
        if (formIsFinished()) {
            return 'Openzetten';
        }

        return 'Afronden';
    };

    const back = () => {
        if(activeTab === 1) {
            setActiveTab(0)
        } else {
            navigate(-1);
        }
    };

    const formDataIsFilledIn = () => {
        const requiredFields = [
            'urgencyCodeOperator',
            'urgencyCodeAuditor',
            'entranceComplaintOperator',
            'entranceComplaintAuditor',
        ];

        const data: any = form as any;

        for(const field of requiredFields) {
            if(!data.values[field] || data.values[field] === '') {
                return false;
            }
        }

        return true;
    };

    const nextTab = () => setActiveTab(activeTab + 1);

    return (
        <>
            {!isAuditor() ?
                <div className={'top-page-error'}>
                    <span>Niet aanpasbaar! (U bent geen auditor)</span>
                </div>
                :
                (userIsNotAuditor()) ?
                    <div className={'top-page-error'}>
                        <span>Niet aanpasbaar! (U bent niet de auditor van deze audit)</span>
                    </div>
                    :
                    <>
                    </>

            }

            <Tabs tabType={Tab} onTabClick={setActiveTab} currentTab={activeTab}>
                <TabBar>
                    <TabBarNavigation>
                        <div className={'conversation-form__back-button'}>
                            <BackButton onClick={back}/>
                        </div>

                        <Tab done={false}>Protocol beoordeling</Tab>
                        <Tab done={false} disabled={!questionsAreAnswered()}>Formulier</Tab>

                        {(isAuditor() && user?.id === props.audit?.auditor_id && !props.audit.completed_at) ?
                            <Button onClick={finish} className={'conversation-form__finish-button'}
                                    disabled={finishButtonDisabled()}>{getFinishButtonText()}</Button>
                            : <></>
                        }
                    </TabBarNavigation>
                </TabBar>

                <TabContent>
                    <ProtocolTab
                        audit={props.audit}
                        scores={props.scores}
                        parts={parts}
                        setParts={setParts}
                        onQuestionChange={onQuestionAnswerChange}
                        onCommentChange={onCommentChange}
                        commentForm={commentForm}
                        disabled={disabled}
                        onUpdateComment = {onUpdateComment}
                        formFinished={formIsFinished()}
                        ntsUrl={props.ntsFormUrl}
                        next={nextTab}
                        canGoNext={questionsAreAnswered()}
                        userIsNotAuditor={userIsNotAuditor()}
                    />
                </TabContent>

                <TabContent>
                    <FormTab
                        formData={form}
                        onFormDataChange={onFormChange}
                        disabled={disabled}
                        ntsUrl={props.ntsFormUrl}
                        canGoNext={formDataIsFilledIn()}
                        complaints={props.entranceComplaints}
                    />
                </TabContent>
            </Tabs>

            {(auditCall) &&
                <AudioPlayer audioBuffer={props.audioFileBuffer} auditCall={auditCall} readonly={userIsNotAuditor()} />
            }
        </>
    );
};

export default ConversationFormContent;
