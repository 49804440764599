import React, {FunctionComponent, useContext, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useAuditTypeOperatorQueryConfig} from '../../../query/Audit/Query';
import MyAudits from "./MyAudits";
import {AuthContext} from "../../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

interface Props {
}

const AuditsAboutMe: FunctionComponent<Props> = () => {

    const [auditConfig, page, setPage] = useAuditTypeOperatorQueryConfig();
    const auditsQuery = useQuery(auditConfig);

    const {isOperator} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isOperator()) navigate('/dashboard');
    }, [isOperator, navigate]);

    return (
        <>
            {
                auditsQuery &&
                <MyAudits title={'Over mij'} audits={auditsQuery.data?.data ?? []} total={auditsQuery.data?.total ?? 0}
                          paginationData={{
                              current: auditsQuery.data?.currentPage ?? 1,
                              max: auditsQuery.data?.lastPage ?? 1,
                              setPage: setPage
                          }}/>
            }
        </>
    );
};

export default AuditsAboutMe;
