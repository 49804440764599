import React, { FunctionComponent } from 'react';
import UserFeedback from 'networking/models/UserFeedback';
import { check, useForm } from 'buro-lib-ts';
import Checkbox from 'components/client/partials/input/Checkbox';
import Modal, { useModal } from 'components/client/partials/modal/Modal';
import UserFeedbackRepository from 'networking/repos/UserFeedbackRepository';

interface Props {
    feedback: UserFeedback;
    onCompleteChange?: (complete: boolean) => void;
}

const UserFeedbackCard: FunctionComponent<Props> = ({feedback, onCompleteChange}) => {
    const repo = new UserFeedbackRepository();

    const [form, onFormChange] = useForm<{ completed: boolean }>({
        completed: check(feedback.completed)
    });

    const [open, active, toggleModal] = useModal();

    const setCompleted = (completed: boolean) => {
        form.set({ completed });
        repo.update(feedback.id!, completed);
    };

    return (
        <>
            <div className={`white normal-checkbox observer observer--still observer-list__grid--feedback`} onClick={toggleModal}>
                <span className="observer__text--left">{ feedback.id }</span>
                <span className="observer__text--left">{ feedback.title }</span>
                <span className="observer__text--grey">{ feedback.user.email }</span>
                <span className="observer__text--grey">{ feedback.user.center }</span>
                <span className="observer__text--grey">{ feedback.created_at }</span>
                <div onClick={e => e.stopPropagation()} className='inline-block'>
                    <Checkbox checked={form.values.completed} onChange={setCompleted} text='' />
                </div>
            </div>

            <Modal active={active} isOpen={open} onCloseClick={toggleModal}>
                <h2 className='mb-1'>Bug #{ feedback.id } { feedback.title }</h2>

                <div className='flex flex-gap--1'>
                    <textarea disabled className='flex-1 input__field' style={{ width: '100%', minHeight: '10rem' }}>{ feedback.feedback }</textarea>
  
                    <div className='flex-1 flex flex--column'>
                        <span>Meldkamer: { feedback.user.center }</span>
                        <span>Melder: { feedback.user.full_name }</span>
                        <span>Datum: { feedback.created_at }</span>
                    </div>
                </div>
            </Modal>
        </>
        
    );
};

export default UserFeedbackCard;