import React, { FunctionComponent, MouseEvent } from 'react';
import Form from '../../../../networking/models/Form';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    form: Form;
    onClick: (form: Form) => void;
    onEditClick: (form: Form) => void;
    setSelectedForm: Function
    toggleDeleteModal: Function
}

const AuditForm: FunctionComponent<Props> = ({ form, onClick, onEditClick, setSelectedForm, toggleDeleteModal }) => {
    const { theme } = useTheme();

    const onFormClick = () => onClick(form);
    const onFormEditClick = (e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();

        onEditClick(form);
    };

    const getFormattedDate = (dateString: string): string => {
        const date = new Date(dateString);

        return `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`;
    };

    return (
        <div className={`audit-form audit-form--${theme.modifier} audit-form-list__grid`} onClick={onFormClick}>
            <span className={'audit-form__text'}>{ form.name }</span>
            <span className={'audit-form__text'}>{ form.center_count }</span>
            <span className={'audit-form__text'}>{ form.audit_count }</span>
            <span className={'audit-form__text'}>{ form.max_error_count }</span>
            <span className={'audit-form__text'}>{ form.max_knockout_count }</span>
            <span className={'audit-form__text'}>{ getFormattedDate(form.created_at!) }</span>
            <span className={'audit-form__text'}>{ getFormattedDate(form.updated_at!) }</span>

            <div>
                <span className={'audit-form__link'} onClick={onFormEditClick}>Wijzigen</span>
                <span className={'audit-form__link'} onClick={(e) => {
                    e.stopPropagation()
                    setSelectedForm(form);
                    toggleDeleteModal();
                }}>Verwijderen</span>
            </div>
        </div>
    );
};

export default AuditForm;
