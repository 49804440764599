import React, { FunctionComponent } from 'react';

import Page from 'components/layout/Page';
import ReportContent from './ReportContent';
import {useReportDetailQueryConfig} from '../../../query/Report/Query';
import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';

const Report: FunctionComponent = () => {
    const { auditId } = useParams();

    const [reportConfig] = useReportDetailQueryConfig(parseInt(auditId!));
    const reportQuery = useQuery(reportConfig);

    return (
        <Page>
            { reportQuery.data &&
                <ReportContent report={reportQuery.data} />
            }
        </Page>
    );
};

export default Report;
