import React, {FunctionComponent, useEffect, useState} from 'react';

import AuditCriteria from 'networking/models/AuditCriteria';
import Audit from 'networking/models/Audit';

import AuditReason from '../../partials/audits/AuditReason';
import AuditRepository from '../../../../networking/repos/AuditRepository';
import {logError} from '../../../utils/devtool/DevTool';

export interface FormErrorRepeat {
    knockout: number;
    error_repeat: number;
}

interface Props {
    audit: Audit;
    repeatCounts: any;
}

const AuditCriteriaBox: FunctionComponent<Props> = ({ audit, repeatCounts }) => {
    const [auditCriteria, setAuditCriteria] = useState<AuditCriteria[]>([]);

    useEffect(() => {
        const auditRepository = new AuditRepository();

        auditRepository.getFailResults(audit.id!).then((criteria: AuditCriteria[]) => {
            setAuditCriteria(criteria);
        }).catch(logError);
    }, [setAuditCriteria]);

    return (
        <div className={'criteria-box'}>
          <div className={'criteria-box__content'}>
              <div className={'criteria-box--card'}>
                    <div className={'flex flex--justify-between flex--align-center'}>
                        <h3>{audit.passed ? "Op/boven de norm" : "Onder de norm"}</h3>
                    </div>

                    {auditCriteria.map((auditCriteria: AuditCriteria) => (
                        <AuditReason
                            criteria={auditCriteria.reason}
                            questionCount={repeatCounts[auditCriteria.reason]}
                            key={auditCriteria.reason} />
                    ))}

                    <span className={`criteria-box__criteria-description`}>Raadpleeg het rapport voor meer informatie</span>
              </div>
          </div>
        </div>
    );
};

export default AuditCriteriaBox;
