import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import {route, useRepository} from 'buro-lib-ts';

import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyenCrossedIcon } from 'assets/icons/eye-crossed.svg';

import AuditModel from 'networking/models/Audit';

import Button from 'components/layout/buttons/Button';
import AuditRepository from 'networking/repos/AuditRepository';
import {useAuth} from '../../../contexts/AuthContext';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    audit: AuditModel;
    withAuditor?: boolean;
    withReleasedButtons?: boolean;
}

const Audit: FunctionComponent<Props> = ({ audit, withAuditor, withReleasedButtons }) => {
    const [released, setReleased] = useState(audit.released);

    const { user, isCenterAdmin } = useAuth();
    const { theme } = useTheme();
    const [ auditRepository ] = useRepository([new AuditRepository()]);

    const auditStatus = (): JSX.Element => {
        if(!audit.completed_at) {
            return <div className={'audit__status audit__status--todo'}>To do</div>;
        }

        if(user!.id === audit.auditor_id && !isCenterAdmin()) {
            return <div className={'audit__status audit__status--done'}>Afgerond</div>;
        }

        return audit.passed
            ? <div className={'audit__status audit__status--approved'}>Op/boven de norm</div>
            : <div className={'audit__status audit__status--denied'}>Onder de norm</div>;
    };

    const shieldAudit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        await auditRepository.softDelete(audit);
        setReleased(false);
    }

    const releaseAudit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        await auditRepository.restore(audit);
        setReleased(true);
    }

    const auditReleased = (): JSX.Element => {
        if(!withReleasedButtons) return <></>;

        if(released) {
            return (
                <Button className="audit-list__released-button audit-list__released-button--green" onClick={shieldAudit}>
                    Vrijgegeven
                </Button>
            );
        } else {
            return (
                <Button className="audit-list__released-button audit-list__released-button--red" onClick={releaseAudit}>
                    Afgeschermd
                </Button>
            );
        }
    }

    return (
      <Link to={route('audit-detail',  [audit.id])} className={`audit audit-list__grid${withAuditor ? '--with-auditor' : ''} audit--${theme.modifier}`}>
          <span className={'audit__text--grey'}>{ audit.id }</span>
          {
              auditReleased()
          }
          {
              auditStatus()
          }
          <span className="">{ audit.original_form }</span>
          {withAuditor &&
              <div className={'audit__triagist'}>
                  <span>{ (audit?.auditor?.first_name) ? audit?.auditor?.first_name + ' ' + audit?.auditor?.last_name : 'Anoniem'}</span>
              </div>
          }

          <div className={'audit__triagist'}>
              <span>{ audit?.operator?.first_name + ' ' + audit?.operator?.last_name }</span>
          </div>

          <span className="">{ audit.created_at }</span>
          <span className={'audit__progress-text'}>
              <span className={'audit__progress-text audit__progress-text--bold'}>{audit.number_of_completed_calls}</span>
              <span className={'audit__progress-text--seperator'}>/</span>
              <span className={'audit__progress-text audit__progress-text--grey'}>{audit.number_of_calls}</span>
          </span>

          {
              (audit.report_downloaded) ? <EyeIcon className={'audit__eye-icon icon-grey--filled'} /> : <EyenCrossedIcon className={'audit__eye-icon icon-grey--filled'} />
          }
      </Link>
    );
};

export default Audit;

