import React, { FunctionComponent, Children } from 'react';
import BoxShortcut from './BoxShortcut';

interface Props {
    children: any;
    white?: any;
    emptyText?: string;
}

const BoxContent: FunctionComponent<Props> = ({ children, white, emptyText }) => {

    const color = white ? 'white' : 'grey';

    const empty = Children.count(children) < 1;

    let index: number = 1;
    const mappedChildren = Children.map(children, (child) => {
        if(child.type === BoxShortcut && !child.props.title) {
            return React.cloneElement(child, { title: index++ });
        }

        return child;
    });

    return (
        <div className={`box__content box__content--${color} ${empty && 'box__content--empty'}`}>
            { empty
                ? (<span className={'box__empty-text'}>{ emptyText }</span>)
                : mappedChildren
            }
        </div>
    );
};

export default BoxContent;