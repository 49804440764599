import React, {FunctionComponent, createContext, useContext, useState} from 'react';
import {BoxData} from '../client/partials/box/Box';

interface BoxListContext {
    activeBox: number;
    scrollToBox: (box: number) => void;
    getBoxIndex: () => number;
    getShortcutIndex: () => number;
    onBoxLoad: (data: BoxData) => void;
    renderId: number;
}

const BoxListContext = createContext<BoxListContext>({} as BoxListContext);

export const useBoxList = () => useContext(BoxListContext);

interface Props {
    children: any;
    onBoxLoad: (data: BoxData) => void;
    scrollTo: (index: number) => void;
    renderId: number;
}

const BoxListContextProvider: FunctionComponent<Props> = ({ children, scrollTo, onBoxLoad, renderId }) => {
    const [activeBox, setActiveBox] = useState<number>(0);

    let box: number = 0;
    let shortcut: number = 0;

    const scrollToBox = (box: number) => {
        scrollTo(box);
        setActiveBox(box);
    };

    const getBoxIndex = (): number => {
        return box++;
    };

    const getShortcutIndex = (): number => {
        return shortcut++;
    };

    return (
        <BoxListContext.Provider value={{ activeBox, scrollToBox, getBoxIndex, getShortcutIndex, onBoxLoad, renderId }}>
            { children }
        </BoxListContext.Provider>
    );
};

export default BoxListContextProvider;

