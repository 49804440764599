import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
// import { getFileDuration } from 'buro-lib-ts';

import audioDecode from 'audio-decode';
import AuditCall from '../../../../../networking/models/AuditCall';
import UploadBox, {FileData} from '../UploadBox';
import SmallAuditCallList from '../../conversations/UploadedAuditCallList';
import Audit from '../../../../../networking/models/Audit';
import AuditCallRepository from '../../../../../networking/repos/AuditCallRepository';

import {warn} from '../../../../../helpers/Toast';
import {logError} from '../../../../utils/devtool/DevTool';



export interface AuditCallFilesState {
    files: FileData[];
}

interface Props {
    auditCalls: AuditCall[];
    setAuditCalls: (auditCalls: AuditCall[]) => void;
    addedAuditCalls: AuditCall[];
    setAddedAuditCalls: (auditCall: AuditCall[]) => void;
    onAuditCallDelete: (auditCall: AuditCall) => void;
    onFinish: () => void;
    active: boolean;
    done: boolean;

    audit?: Audit;
}

const AuditCallsTab: FunctionComponent<Props> = ({ onAuditCallDelete, done, audit, auditCalls, setAuditCalls, addedAuditCalls, setAddedAuditCalls, onFinish, active }) => {

    const [fileStates, setFileStates] = useState<AuditCallFilesState[]>([]);
    const [currentAuditCallNumber, setCurrentAuditCallNumber] = useState<number>(0);

    const [auditCallRepository] = [
        new AuditCallRepository(audit?.id ?? 0)
    ];

    const getFileDuration = async (file: File | string): Promise<number> => {
        try {
            const path = typeof file === 'string' ? file : URL.createObjectURL(file);
            const response = await fetch(path);
            const arrayBuffer = await response.arrayBuffer();
            const audioBuffer = await audioDecode(arrayBuffer);
            const duration = audioBuffer.duration;
            return Math.round(duration);
        } catch (e) {
            try {
                const endpoint = process.env.REACT_APP_API_ENDPOINT;
                const token = JSON.parse(sessionStorage.getItem('SESSION_STORE')!!).auth_token;
                const formData = new FormData();
                formData.append('file', file);
                const response = await fetch(`${endpoint}/audio/duration`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                return Number(await response.text());
            } catch (e) {
                warn('Fout bij uploaden audiobestand!');

                throw e;
            }
        }
    };

    const resetTabState = useCallback(() => {
        setFileStates([]);
        setCurrentAuditCallNumber(0);
    }, [setFileStates, setCurrentAuditCallNumber]);

    const setupFileStates = useCallback(() => {
        setFileStates(auditCalls.map((auditCall) => {
            const files: FileData[] = [];

            if(auditCall.has_audio_file) {
                files.push({ extension: 'mp3', progress: 100 } as FileData);
            }

            if(auditCall.has_nts_form) {
                files.push({ extension: 'pdf', progress: 100 } as FileData);
            }

            return { files };
        }));

        const firstUncompletedAuditCall = auditCalls.findIndex(a => (!a.has_nts_form || !a.has_audio_file));

        setCurrentAuditCallNumber(firstUncompletedAuditCall > -1 ? firstUncompletedAuditCall : 0);
    }, [setFileStates, auditCalls, setCurrentAuditCallNumber]);

    useEffect(() => {
        if(!audit) {
            resetTabState();
        }
    }, [audit, resetTabState]);

    useEffect(() => {
        if(fileStates.length === auditCalls.length) return;

        setupFileStates();
    },[auditCalls, setupFileStates]);

    const updateFileState = (fileData: FileData[]) => {
        const newFileStates = [...fileStates];
        newFileStates[currentAuditCallNumber].files = fileData;

        setFileStates(newFileStates);
    };

    const findFileIndex = (file: FileData) => {
        return fileStates[currentAuditCallNumber].files.findIndex((f) => f.file.name === file.file.name);
    };

    const onFileUpload = (file: FileData) => {
        updateFileState([...fileStates[currentAuditCallNumber].files, file]);

        if(file.extension === 'pdf') {
            uploadNTSForm(file);
        } else {
            uploadAudioFile(file).catch(logError);
        }
    };

    const removeFile = (file: FileData) => {
        const files = getCurrentFiles();

        const newFiles = [...files];
        newFiles.splice(findFileIndex(file), 1);

        updateCurrentFileList(newFiles);
    };

    const setAuditCallHasAudioFile = () => {
        const auditCallList = [...auditCalls];
        auditCallList[currentAuditCallNumber].has_audio_file = true;
        setAuditCalls(auditCallList);
    };

    const setAuditCallHasNtsFile = () => {
        const auditCallList = [...auditCalls];
        auditCallList[currentAuditCallNumber].has_nts_form = true;
        setAuditCalls(auditCallList);
    };

    const uploadAudioFile = async (file: FileData) => {
        let duration = 0;
        try {
            duration = await getFileDuration(file.file);
        } catch (e) {
            removeFile(file);
        }
        const data: FormData = new FormData();
        data.append('file', file.file);
        data.append('duration', duration.toString());

        updateAuditDuration(currentAuditCallNumber, duration);
        auditCalls[currentAuditCallNumber].audio_file_name = file.file.name;

        try {
            await auditCallRepository.save(auditCalls[currentAuditCallNumber]);
        } catch (ignored) {}

        auditCallRepository.uploadFile(auditCalls[currentAuditCallNumber], data, (event) => {
            updateUploadProgress(event.loaded / event.total * 100, file);
        }).then(() => {
            setAuditCallHasAudioFile();
        }).catch((e) => {
            removeFile(file);

            logError(e);
            warn('Fout bij uploaden audiobestand!');
        });
    };

    const uploadNTSForm = (file: FileData) => {
        const data: FormData = new FormData();
        data.append('file', file.file);

        auditCallRepository.uploadFile(auditCalls[currentAuditCallNumber], data, (event) => {
            updateUploadProgress(event.loaded / event.total * 100, file);
        }).then(() => {
            setAuditCallHasNtsFile();
        }).catch((e) => {
            removeFile(file);

            logError(e);
            warn('Fout bij uploaden NTS formulier!');
        });
    };

    const updateAuditDuration = (auditCallNumber: number, duration: number) => {
        const auditCallList = [...auditCalls];

        auditCallList[auditCallNumber].duration = duration;

        setAuditCalls(auditCallList);
    };

    const updateUploadProgress = (progress: number, fileData: FileData) => {
        setFileStates((prevState) => {
            const newFileStates = [...prevState];
            const fileList = newFileStates[currentAuditCallNumber].files;

            const fileIndex = fileList.findIndex((value) => {
                return value.extension === fileData.extension
            });

            if(fileIndex === -1) {
                fileList[fileList.length] = fileData;
            } else {
                fileList[fileIndex].progress = progress;
            }

            return newFileStates;
        });
    };

    const addAuditCall = () => {
        const newAddedAuditCalls = [...addedAuditCalls, auditCalls[currentAuditCallNumber]];
        setAddedAuditCalls(newAddedAuditCalls);

        const newCurrentAuditCallNumber = currentAuditCallNumber + 1;

        if(newCurrentAuditCallNumber !== auditCalls.length) {
            setCurrentAuditCallNumber(newCurrentAuditCallNumber);
        }

        if(newAddedAuditCalls.length === auditCalls.length) {
            onFinish();
        }
    };

    const uploadsCompleted = () => {
        if(!canAddNewAuditCalls()) return false;

        const files = getCurrentFiles();

        if(files.length < 2) {
            return false;
        }

        for (let file of files) {
            if(file.progress !== 100) {
                return false;
            }
        }

        return true;
    };

    const addButtonDisabled = () => {
        return (addedAuditCalls[currentAuditCallNumber] !== undefined || !uploadsCompleted());
    };

    const canAddNewAuditCalls = () => {
        return !done;
    };

    const nextAuditCall = () => {
        setCurrentAuditCallNumber(currentAuditCallNumber + 1);
    };

    const previousAuditCall = () => {
        setCurrentAuditCallNumber(currentAuditCallNumber - 1);
    };

    const getCurrentFiles = () => {
        if(!canAddNewAuditCalls() || !fileStates.length) return [];

        return fileStates[currentAuditCallNumber].files;
    };

    const updateCurrentFileList = (files: FileData[]) => {
        updateFileState(files);
    };

    const auditCallIsAdded = () => {
        return addedAuditCalls[currentAuditCallNumber] !== undefined;
    };

    if(!audit || !audit.id || !auditCalls.length) return null;

    if(!active) {
        return null;
    }

    return (
        <div className={'audit-create-box__calls-tab'}>
           <UploadBox
               auditCallNumber={currentAuditCallNumber + 1}
               auditCallCount={auditCalls.length}
               files={getCurrentFiles()}
               setFiles={updateCurrentFileList}
               onFileUpload={onFileUpload}
               onAddClick={addAuditCall}
               addDisabled={addButtonDisabled()}
               fileUploadAllowed={canAddNewAuditCalls()}
               onNextClick={nextAuditCall}
               onPreviousClick={previousAuditCall}
               showAddButton={!auditCallIsAdded()}
               uploadsCompleted={uploadsCompleted()}
           />

            <div className={'audit-create-box__calls-container'}>
                <SmallAuditCallList auditCalls={addedAuditCalls} onDeleteClick={onAuditCallDelete} />
            </div>
        </div>
    );
};

export default AuditCallsTab;
