import React, {FunctionComponent, Suspense} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useQuery, UseQueryResult} from '@tanstack/react-query';

import Page from 'components/layout/Page';
import FormQuestionBox from '../../partials/conversations/form/FormQuestionBox';
import Button from 'components/layout/buttons/Button';

import FormPart from '../../../../networking/models/FormPart';
import {useTheme} from '../../../contexts/ThemeContext';
import {useFormDetailQueryConfig} from '../../../query/Form/Form';
import {useFormPartIndexQueryConfig} from '../../../query/FormPart/Query';
import FormModel from '../../../../networking/models/Form';

export interface Props {}

const Form: FunctionComponent<Props> = () => {
    const {theme} = useTheme();

    const formId = parseInt(useParams().formId!);

    const [formConfig] = useFormDetailQueryConfig(formId);
    const [partConfig] = useFormPartIndexQueryConfig(formId);

    const query: [UseQueryResult<FormModel>, UseQueryResult<FormPart[]>] = [
        useQuery(formConfig),
        useQuery(partConfig)
    ];

    return (
        <Page scrollable className={`form-page form-page--${theme.modifier} settings-page__content`}>
            <Suspense>
                { (query[0].data && query[1].data) &&
                    <>
                        <div className={'form-page__header'}>
                            <div className="flex flex--justify-between">
                                <h1>Formulier: { query[0].data.name }</h1>

                                <Link to="/settings">
                                    <Button className="button float-right">Terug</Button>
                                </Link>
                            </div>

                            <div>
                                <p>Max foutieve verplichte antwoorden: { query[0].data.max_knockout_count }</p>
                                <p>Max foutieve noodzakelijke antwoorden: { query[0].data.max_error_count }</p>
                            </div>
                        </div>

                        <div className="form-page__content">
                            { query[1].data.map((part, index) =>  (
                                <FormQuestionBox key={part.id} partIndex={index} part={part} readonly={true}/>
                            ))}
                        </div>
                    </>
                }
            </Suspense>
        </Page>
    );
};

export default Form
