import React, {FunctionComponent} from 'react';

export interface PaginationProps {
    current: number;
    max: number;
    setPage: (page: number) => void;
}

const Pagination: FunctionComponent<PaginationProps> = ({current, max, setPage}) => {

    const previousClick = () => {
        if (hasPrevious) setPage(current - 1);
    }

    const nextClick = () => {
        if (hasNext) setPage(current + 1);
    }

    const hasPrevious = current > 1;
    const hasNext = current < max;

    return (
        <div className="pagination">
            <span className={`pagination__button white-card ${!hasPrevious && 'button-basic--disabled'}`}
                  onClick={previousClick}>Vorige</span>
            <span className="pagination__pages white-card">{`${current} van ${max}`}</span>
            <span className={`pagination__button white-card ${!hasNext && 'button-basic--disabled'}`}
                  onClick={nextClick}>Volgende</span>
        </div>
    );
};

export default Pagination;
