import React, { FunctionComponent } from "react";

interface Props {
  children: any;
  className?: string;
  show: boolean;
}

const Tooltip: FunctionComponent<Props> = ({ children, className, show }) => {
    return <div className={`tooltip ${className} ${!show ? 'tooltip__hidden' : ''}`}>{children}</div>;
};

export default Tooltip;
