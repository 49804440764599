import React, {FunctionComponent, useState, useEffect, useCallback} from 'react';
import {app} from 'buro-lib-ts';

import {ReactComponent as CheckIcon} from 'assets/icons/check.svg';
import {ReactComponent as WarningIcon} from 'assets/icons/warning.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/close.svg';

interface ToastData {
    id: number,
    title: String,
    description: String,
    type: String,
}

export interface Toast {
    type: string;
    message: string;
}

const ToastContainer: FunctionComponent = () => {
    const [toasts, setToasts] = useState<ToastData[]>([] as ToastData[]);

    const deleteLastToast = useCallback(() => {
        if(!toasts.length) return;

        const newState = [...toasts];
        newState.shift();

        setToasts(newState);
    }, [toasts]);

    useEffect(() => {
        app().onEvent('TOAST', (toast: string | Toast) => {
            setToasts((prevState) => {
                const type = typeof toast === 'object' ? toast.type : toast;
                const description = typeof toast === 'object' ? toast.message : (type === "success" ? 'De actie is succesvol uitgevoerd.' : 'De actie is niet gelukt');

                const newToast: ToastData = {
                    id: Math.floor(Math.random() * 10000),
                    title: type === "success" ? "Succes" : "Waarschuwing",
                    description: description,
                    type: type
                };

                return [...prevState, newToast];
            });
        });
    }, [setToasts]);

    useEffect(() => {
        const interval = setInterval(deleteLastToast, 5000);

        return () => {
            clearInterval(interval);
        }
    }, [deleteLastToast]);

    const deleteToast = (id: number) => {
        setToasts((prevState) => {
            const toasts = [...prevState];

            const index = toasts.findIndex((t: ToastData) => t.id === id);

            toasts.splice(index, 1);

            return toasts;
        });
    }

    return (
        <div className={`toast-container bottom-right`}>
            {
                toasts.map((toast: any, i: number) =>
                    <div
                        key={i}
                        className={`toast toast--${toast.type} bottom-right`}>
                        <div className={"toast__close-section"}>
                            <button className="toast__close" onClick={() => deleteToast(toast.id)}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className={"toast__content"}>
                            <div className="toast__image">
                                {toast.type === 'success' ? <CheckIcon /> : <WarningIcon />}
                            </div>
                            <div>
                                <p className="toast__title">{toast.title}</p>
                                <p className="toast__message">
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

// export ToastContainer.show
export default ToastContainer;
