import React, {FunctionComponent, useEffect, useState} from 'react';
import {input, select, useForm, V} from 'buro-lib-ts';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthContext';

import Page from '../../../layout/Page';
import TabBarHeader from '../../../layout/tabs/TabBarHeader';
import TabBar from '../../../layout/tabs/TabBar';
import BackButton from '../../../layout/buttons/BackButton';
import TabNavigationContent from '../../../layout/tabs/TabNavigationContent';
import AccountCard from './AccountCard';
import Card from '../../../layout/Card';

import { success, warn } from '../../../../helpers/Toast';

import AuthRepository from 'networking/repos/AuthRepository';
import UserRepository from 'networking/repos/UserRepository';
import SuperAdminRepository from 'networking/repos/SuperAdminRepository';
import {logError} from '../../../utils/devtool/DevTool';
import {useTheme} from '../../../contexts/ThemeContext';
import {UserForm} from '../../../form/User';
import {MV} from "../../../../helpers/Validation";
import CircleLoader from 'components/client/partials/loading/CircleLoader';

const Account: FunctionComponent = () => {
    const navigate = useNavigate();

    const { theme } = useTheme();
    const { user } = useAuth();

    const [userForm, onFormChange] = useForm<UserForm>({
        first_name: input('', [V.required, MV.size(1,50)]),
        last_name: input('', [V.required, MV.size(1,50)]),
        phone: input('', [MV.phoneNumber()]),
        email: input('', [V.required, MV.email]),
        center: select<UserForm, number>(-1, null, []),
        residence: input(''),
    });

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        userForm.set(user!);
    },[user]);

    const back = () => {
        navigate(-1);
    };

    // TODO: Fix if-else and make subtype for super admin
    const onAccountSave = async () => {
        const request = { ...userForm.values, roles: user!.roles.map(r => r.role) };

        setLoading(true);
        try {
            if(userForm.values.center !== -1) {
                const userRepo = new UserRepository(userForm.values.center);
                await userRepo.update(user?.id!, request);
                setLoading(false);
            } else {
                //User has no center, so it has to be a super-admin.
                const superAdminRepo = new SuperAdminRepository();
                await superAdminRepo.update(user?.id!, request);
                setLoading(false);
            }
            success('Gegevens opgeslagen!');
        } catch (e) {
            logError(e);
            warn('Oeps, er is iets misgegaan!');
        }
    };

    if(!user) {
        return null;
    }

    const sendMail = async () => {
        const authRepository = new AuthRepository();

        setLoading(true);

        try {
            await authRepository.forgotPassword({'email': userForm.values.email});
            setLoading(false);
            success('E-mail verzonden!');
        } catch (e) {
            setLoading(false);
            logError(e);
            warn('Oeps, er is iets misgegaan!');
        }
    }

    return (
        <Page className={`account-page account-page--${theme.modifier}`}>
            <TabBar>
                <TabBarHeader>
                    <span />

                    <BackButton onClick={back} />
                </TabBarHeader>
            </TabBar>

            <TabNavigationContent scrollable>
                <AccountCard
                    user={user!}
                    form={userForm}
                    onFormChange={onFormChange}
                    onSave={onAccountSave} />

                {(!user.sso_identifier) && (
                    <div className={'account-page__actions flex'}>
                        <Card className={'account-page__card'}>
                            <div className={'flex flex--justify-between'}>
                                <div>
                                    <p className={'white-card__title'}>Wachtwoord wijzigen</p>
                                    <p>Volg de instructies in de e-mail die naar u wordt verzonden.</p>
                                </div>

                                <div className={'flex flex--align-center'}>
                                    <p className={'account-page__card__text'} onClick={sendMail}>Wijzigen</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                )}

                {loading && <CircleLoader center={true}/>}
            </TabNavigationContent>
        </Page>
    );
};

export default Account;
