import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';

import { useSideBar } from 'components/contexts/SideBarContext';

import PlayControls from './PlayControls';
import ProgressBar from './ProgressBar';
import VolumeBar from './VolumeBar';
import AudioWave from './AudioWave';

import Modal from '../modal/Modal';
import { useModal } from '../modal/Modal';

import WavePointInputList from './wavepoint/WavePointInputList';
import WavePointCommentList from './wavepoint/WavePointCommentList';

import WayPoint from 'networking/models/WayPoint';
import WayPointRepository from '../../../../networking/repos/WayPointRepository';

import { ReactComponent as MaximiseIcon } from 'assets/icons/maximise.svg';
import { ReactComponent as MinimiseIcon } from 'assets/icons/minimalise.svg';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import AuditCall from '../../../../networking/models/AuditCall';
import CircleLoader from '../loading/CircleLoader';
import { useAuth } from 'components/contexts/AuthContext';
import {logError} from '../../../utils/devtool/DevTool';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    audioBuffer?: ArrayBuffer;
    auditCall: AuditCall;
    readonly?: boolean;
}

const AudioPlayer: FunctionComponent<Props> = ({ audioBuffer, auditCall, readonly }) => {
    const { theme } = useTheme();
    const { menuOpen } = useSideBar();
    const { user } = useAuth();

    const [modalOpen, modalActive, toggleModal] = useModal();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [audioUrl, setAudioUrl] = useState<string>();

    const [wavePoints, setWavePoints] = useState<WayPoint[]>([]);

    const [fullScreen, setFullscreen] = useState<boolean>(false);
    const [seconds, setSeconds] = useState<number>(0);
    const [playing, setPlaying] = useState<boolean>(false);

    const audioRef = useRef<HTMLAudioElement>(null);
    const componentWillUnmount = useRef(false)

    const [wayPointRepository] = [
        new WayPointRepository(auditCall.id ?? 0)
    ];

    const togglePlaying = useCallback(() => { // TODO: -> useEffect
        setPlaying((playing) => {
            if(playing) {
                audioRef.current?.pause();
            } else {
                audioRef.current?.play();
            }

            return !playing;
        });
    }, [playing, setPlaying]);

    const playAudio = useCallback(() => {
        if(!audioRef.current) {
            return
        }

        audioRef.current.play().then(() => {
            setPlaying(true);
        });
    }, [setPlaying]);

    const setTime = useCallback((time: number) => {
        if(!audioRef.current) {
            return;
        }

        audioRef.current.currentTime = time;

        if(!playing) {
            playAudio();
        }

        setSeconds(time);
    }, [playing, playAudio, setSeconds]);

    const subtractTime = useCallback(() => {
        if(!audioRef.current) {
            return;
        }

        setTime(
            Math.max((audioRef.current.currentTime - 10), 0)
        );
    }, []);

    const addTime = useCallback(() => {
        if(!audioRef.current) {
            return;
        }

        setTime(
            (audioRef.current.currentTime + 10) % audioRef.current.duration
        );
    }, [setTime]);

    const onKeyPressed = useCallback((e: KeyboardEvent) => {
        if(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement)
            return;

        const space = 32;
        const leftArrow = 37;
        const rightArrow = 39;

        switch (e.keyCode) { // TODO: change to key
            case space:
                togglePlaying();
                break;
            case leftArrow:
                subtractTime();
                break;
            case rightArrow:
                addTime();
                break;
        }
    },[togglePlaying, subtractTime, addTime]);

    // This is componentWillUnmount
    useEffect(() => {
        return () => {
            componentWillUnmount.current = true
        }
    }, []);

    useEffect(() => {
        return () => {
            // This line only evaluates to true after the componentWillUnmount happens
            // clean up the blob only then
            if (componentWillUnmount.current) {
                window.URL.revokeObjectURL(audioUrl!);
            }
        }
    }, []);

    useEffect(() => {
        const newOrder = wavePoints.sort((a,b) => {
            return a.seconds - b.seconds;
        })
        setWavePoints(indexWayPoints(newOrder))
    }, [wavePoints])

    useEffect(() => {
        document.removeEventListener('keyup', onKeyPressed, true);

        wayPointRepository.all().then((wayPoints) => {
            wayPoints = indexWayPoints(wayPoints);

            setWavePoints(wayPoints);
        }).catch(logError);

        document.addEventListener('keyup', onKeyPressed);
    }, [setWavePoints]);

    useEffect(() => {
        if (!audioBuffer || loaded) return;

        try {
            const blob = new Blob([audioBuffer], { type: 'audio/wav' });
            const audioUrl = window.URL.createObjectURL(blob);
            setAudioUrl(audioUrl);
        } catch (error) {
            console.error('Error creating object URL:', error);
        }
    }, [audioBuffer, loaded, setAudioUrl]);


    const indexWayPoints = (wayPoints: WayPoint[]) => {
        wayPoints.forEach((wayPoint, index) => {
            wayPoint.index = index + 1;
        });

        return wayPoints;
    };

    const deleteWayPoint = (wayPoint: WayPoint) => {
        if(wayPoint.id) {
            wayPointRepository.destroy(wayPoint).catch(logError);
        }

        let wayPointList = [...wavePoints];

        const index = getWayPointIndex(wayPoint);

        wayPointList.splice(index, 1);

        wayPointList = indexWayPoints(wayPointList);

        setWavePoints(wayPointList);
    };

    const getWayPointIndex = (wayPoint: WayPoint) => {
        return wavePoints.findIndex((value) => {
            return value.seconds === wayPoint.seconds;
        });
    };

    const toggleFullScreen = () => {
        toggleModal();
        setFullscreen(!fullScreen);
    };

    const onLoad = () => {
        setLoaded(true);
    };

    const onTimeUpdate = () => {
        if(!audioRef.current) {
            return;
        }

        setSeconds(audioRef.current.currentTime);
    };

    const onProgressBarClick = (seconds: number) => {
        if(!audioRef.current) {
            return
        }

        audioRef.current.currentTime = seconds;

        if(!playing) {
            playAudio();
        }

        setSeconds(seconds);
    };

    const setVolume = (volume: number) => {
        if(!audioRef.current) {
            return;
        }

        audioRef.current.volume = volume;
    };

    const setPlaySpeed = (speed: number) => {
        if(!audioRef.current) {
            return;
        }

        audioRef.current.playbackRate = speed;
    };

    const stopPlaying = () => {
        if(!playing) return true;

        audioRef.current?.pause();

        setPlaying(false);
    };

    const addWavePoint = (seconds: number) => {
        if(readonly) return;

        // Skip wave point if already exists
        for(let wavePointEl of wavePoints) {
            if(Math.round(wavePointEl.seconds) === Math.round(seconds)) {
                return;
            }
        }

        stopPlaying();

        const wavePoint: WayPoint = {
            index: wavePoints.length + 1,
            seconds: seconds,
            comment: null,
        };

        setWavePoints(
            [...wavePoints, wavePoint]
        );
    };

    const saveWayPoint = (wavePoint: WayPoint) => {
        const newWavePoints = [...wavePoints];
        newWavePoints[wavePoint.index - 1] = wavePoint;

        setWavePoints(newWavePoints);
        wayPointRepository.save(wavePoint).then((savedWayPoint) => {
            const newState = [...newWavePoints];

            newState[wavePoint.index - 1].id = savedWayPoint.id;
            setWavePoints(newState);
        }).catch(logError);
    };

    const onWayPointEdit = (wayPoint: WayPoint) => {
        wayPointRepository.save(wayPoint).catch(logError);

        const wayPointList = [...wavePoints];

        const index = getWayPointIndex(wayPoint);

        wayPointList[index] = wayPoint;

        setWavePoints(wayPointList);
    };

    const getClassName = (): string => {
        return `
            audio-player 
            audio-player--${theme.modifier}
            ${!menuOpen ? 'audio-player--big' : ''} 
            ${fullScreen ? 'audio-player--fullscreen' : ''}
        `;
    };

    const ResizeIcon = fullScreen ? MinimiseIcon : MaximiseIcon;

    const openWavePoints = wavePoints.filter(wavePoint => wavePoint.comment === null);
    const filledInWavePoints = wavePoints.filter(wavePoint => wavePoint.comment !== null);

    return (
        <React.Fragment>
            <figure className={getClassName()} tabIndex={0}>
                { audioUrl &&
                    <audio
                        src={audioUrl}
                        ref={audioRef}
                        onLoadedMetadata={onLoad}
                        onEnded={() => setPlaying(false)}
                        onTimeUpdate={onTimeUpdate}/>
                }

                { (!loaded) &&
                    <div className={'audio-player__controls'}>
                        <CircleLoader />
                    </div>
                }

                { (loaded && audioRef.current !== null) &&
                    <div className={'audio-player__controls'}>
                        <PlayControls
                            onTimeAdd={addTime}
                            onTimeSubtract={subtractTime}
                            onPlayToggle={togglePlaying}
                            onPlaySpeedUpdate={setPlaySpeed}
                            playing={playing}
                            playSpeed={audioRef.current.playbackRate} />

                        <ProgressBar
                            onClick={onProgressBarClick}
                            onBarDoubleClick={addWavePoint}
                            value={seconds}
                            length={audioRef.current.duration}
                            wavePoints={wavePoints} />

                        <div className={'audio-player__container audio-player__file'}>
                            <span className={'audio-player__file--main'}>Gesprek {  auditCall.id }</span>
                            <span className={'audio-player__file--secondary'}>{ auditCall.audio_file_name }</span>
                        </div>

                        <div className={'audio-player__container'}>
                            <VolumeBar onVolumeUpdate={setVolume} />
                        </div>

                        <div className={'audio-player__container audio-player__resize'}>
                            <ResizeIcon className={'icon-grey icon-grey--filled audio-player__resize-icon'} onClick={toggleFullScreen} />
                        </div>
                    </div>
                }
            </figure>

            { (audioRef.current !== null && audioBuffer) &&
                <Modal isOpen={modalOpen} active={modalActive} onCloseClick={toggleFullScreen} hidden={false} className={'audio-player__modal'}>
                    <div className={'flex flex--justify-between m-bottom-4'}>
                        <div className={'flex flex--align-center'}>
                            <InfoIcon className={'audio-player__info-icon'} />
                            {readonly ?
                            <span className={'audio-player__hint-text'}>U mag dit bestand alleen beluisteren.</span>
                            :
                            <span className={'audio-player__hint-text'}>Dubbelklik om een opmerking te plaatsen.</span>}
                        </div>
                    </div>

                    <AudioWave
                        audioBuffer={audioBuffer}
                        currentSeconds={seconds}
                        duration={audioRef.current.duration}
                        wavePoints={wavePoints}
                        onWaveClick={setTime}
                        onWaveDoubleClick={addWavePoint} />

                    <WavePointInputList
                        wavePoints={openWavePoints}
                        onInputSubmit={saveWayPoint}
                        onWayPointCancel={deleteWayPoint} />

                    { (filledInWavePoints.length > 0) &&
                        <WavePointCommentList
                            wavePoints={filledInWavePoints}
                            onWayPointEdit={onWayPointEdit}
                            editable={user?.id === auditCall.auditor_id && !readonly}
                            onDeleteClick={deleteWayPoint}/>
                    }
                </Modal>
            }
        </React.Fragment>
    );
};



export default AudioPlayer;
