import React, {useState, useEffect, FunctionComponent} from 'react';
import {useNavigate, RouteProps, useLocation, useParams} from 'react-router-dom';

// Components
import Page from 'components/layout/Page';

// Contexts
import {useAuth} from 'components/contexts/AuthContext';

// Repository
import {logError} from '../../../utils/devtool/DevTool';

interface Params {
    center_abbreviation: string;
}

const LoginSSO: FunctionComponent<RouteProps> = (center_abbreviation) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();


    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');


    const [error, setError] = useState<boolean>(false);

    const { loginWithoutCredentials } = useAuth();

    useEffect(() => {
        if(!code) {
            setError(true);
            return;
        }
        // get the REACT_APP_API_ENDPOINT from the .env file
        const endpoint = process.env.REACT_APP_API_ENDPOINT;
        fetch(`${endpoint}/api/auth/${params.center_abbreviation}/callback?code=${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if(data.token) {
                    loginWithoutCredentials(data.token);
                    const navigateTo = sessionStorage.getItem("redirectTo") || '/';
                    sessionStorage.removeItem("redirectTo");
                    navigate(navigateTo);
                } else {
                    setError(true);
                }
            })
            .catch(e => {
                logError(e);
                setError(true);
            });





    }, [code, setError, loginWithoutCredentials]);

    return (
        <Page className="auth__page">
            <form className="auth__page__form">
                <div className="auth__page__form__row auth__page__form__row__meldkamer">
                    <div className="auth__page__form__row__col" />
                </div>

                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">Inloggen</h1>
                </div>

                {(error) ? <p>Oeps er is iets misgegaan. Neem contact op met de beheerder van uw meldkamer.</p> : <p>Een ogenblik geduld alstublieft.</p>}
            </form>
        </Page>
    )
}

export default LoginSSO
