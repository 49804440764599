import React, {FunctionComponent, useState} from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';

import {FormAccessor, TabContent, route} from 'buro-lib-ts';
import {useQuery} from '@tanstack/react-query';

import User from 'networking/models/User';

import Page from 'components/layout/Page';
import Tabs from 'components/layout/tabs/Tabs';
import TabBar from 'components/layout/tabs/TabBar';
import Button from 'components/layout/buttons/Button';
import BackButton from 'components/layout/buttons/BackButton';
import Modal, { useModal } from 'components/client/partials/modal/Modal';
import TabBarNavigation from 'components/layout/tabs/TabBarNavigation';

import { success, warn } from 'helpers/Toast';
import {UserForm, useUserForm} from '../../../form/User';
import UserModal from '../../../client/partials/users/UserModal';
import CenterSettings from '../../../client/pages/settings/CenterSettings';
import {useCenterDetailQueryConfig} from '../../../query/Center/Query';
import {useStatisticsUserQueryConfig} from '../../../query/Statistics/Query';
import {useUserCollectionMutations} from '../../../query/User/Mutation';
import {logError} from '../../../utils/devtool/DevTool';
import {useCenterDeleteMutation} from '../../../query/Center/Mutation';

// TODO: Create Update/CreateAdminUser & EditAdminUser
const CenterDetailPage: FunctionComponent = () => {
    const { id } = useParams()!;
    const centerId = Number(id);

    const navigate = useNavigate();

    const deleteCenter = useCenterDeleteMutation(centerId);
    const { createUser } = useUserCollectionMutations(centerId);

    const [centerConfig] = useCenterDetailQueryConfig(centerId);
    const [statisticsConfig] = useStatisticsUserQueryConfig(centerId);

    const centerQuery = useQuery(centerConfig);
    const statisticsQuery = useQuery(statisticsConfig);

    const [form, onFormChange] = useUserForm(centerId);

    const [selectedUser, setSelectedUser] = useState<User>();
    const [modalOpen, modalActive, toggleModal] = useModal();

    const onBackClick = () => {
        navigate('/admin');
    };

    const onUserSaveClick = async (form: FormAccessor<UserForm>, roles: string[]) => {
        if(!form.isValid) return;
        roles.push('centerAdmin');

        createUser.mutateAsync({ ...form.values, roles }).then(() => {
            closeModal();
            success('Gebruiker is opgeslagen.');
        }).catch((e: any) => {
            logError(e);
            warn('Oeps, er is iets misgegaan!');
        });
    };

    const openModal = () => {
        form.set({ center: centerId });
        toggleModal();
    };

    const closeModal = () => {
        setSelectedUser(undefined);
        form.set({ center: centerId });

        toggleModal();
    };

    const onDeleteClick = async () => {
        deleteCenter.mutateAsync().then(() => {
            navigate('/admin');
            success('Meldkamer is verwijderd!');
        }).catch((e: any) => {
            logError(e);
            warn('Oeps, er is iets misgegaan!');
        });
    };

    const userStats = statisticsQuery.data;
    const center = centerQuery.data;

    return (
        <Page className={'center-detail-page'} scrollable>
            <Tabs noErrors>
                <TabBar>
                    <TabBarNavigation>
                        <div className={'flex flex--justify-between w-100 flex--align-center'}>
                            <div>
                                <Link to={'/admin'} className={'tab-navigation__breadcrumbs'}>Meldkameroverzicht / </Link>
                                <span className={'tab-navigation__breadcrumbs tab-navigation__breadcrumbs--active'}>Meldkameroverzicht</span>
                            </div>

                            <BackButton onClick={onBackClick} />
                        </div>
                    </TabBarNavigation>
                </TabBar>

                <TabContent>
                    <p className={'center-detail-page__title'}>Meldkamer</p>

                    <div className={'flex flex--justify-between center-detail-page__header'}>
                        <h2 className={'center-detail-page__subtitle'}>{ center?.name }</h2>
                        <div>
                            <Button onClick={openModal}>Beheerder aanmaken</Button>
                            &nbsp;
                            <Button del onClick={onDeleteClick}>Verwijderen</Button>
                        </div>
                    </div>

                    <CenterSettings center={centerId} />

                    { userStats ?
                        <div className={'flex flex--justify-between'}>
                            <div className={'center-detail-page__card'}>
                                <h1 className='heading4--black'>Medewerkers ({ userStats.total })</h1>

                                { userStats.roles.map((role: any) => (
                                    <p className={'center-detail-page__role'} key={role.name}>
                                        <span>Aantal {role.name}s:</span>
                                        <span>{ role.count }</span>
                                    </p>
                                )) }

                                <Link to={route('admin-center-admins', { id: centerId })} className={'center-detail-page__link'}>Bekijk alle beheerders</Link>
                            </div>

                            <div className={'center-detail-page__card'}>
                                <h1 className='heading4--black'>Login pogingen</h1>
                                <p>Om de inlog pogingen te bekijken klik op de onderstaande link:</p>

                                <Link to={'/admin/logins'} className={'center-detail-page__link center-detail-page__link--left'}>Bekijk alle inlogpogingen</Link>
                            </div>
                        </div>
                        : <React.Fragment />
                    }
                </TabContent>
            </Tabs>

            <Modal isOpen={modalOpen} active={modalActive} onCloseClick={closeModal} className='user-modal'>
                <UserModal
                    user={selectedUser}
                    form={form}
                    onFormChange={onFormChange}
                    closeModal={closeModal}
                    onSaveClick={onUserSaveClick} />
            </Modal>
        </Page>
    );
};

export default CenterDetailPage;
