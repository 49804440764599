import React, { FunctionComponent } from 'react';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    children: any;
    onClick: () => void;
    className?: string;
}

const CancelButton: FunctionComponent<Props> = ({ children, onClick, className }) => {
    const { theme } = useTheme();

    return (
      <button className={`button button-cancel button-cancel--${theme.modifier} ${className ? className : ''}`} onClick={onClick}>
          { children }
      </button>
    );
};

export default CancelButton;
