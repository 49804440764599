import React, {FunctionComponent} from "react";
import SidebarMenu from "../../layout/sidebar/SidebarMenu";
import SidebarMenuItem from "../../layout/sidebar/SidebarMenuItem";
import {route} from "buro-lib-ts";

import {ReactComponent as ContactsIcon} from '../../../assets/icons/contacts-border.svg';
import {ReactComponent as ContactsIconActive} from '../../../assets/icons/contacts.svg';
import {ReactComponent as ListIcon} from '../../../assets/icons/list-border.svg';
import {ReactComponent as ListIconActive} from '../../../assets/icons/list.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/icon-users-border.svg';
import {ReactComponent as UserIconActive} from '../../../assets/icons/icon-users.svg';
import {ReactComponent as SpyIcon} from '../../../assets/icons/spy-border.svg';
import {ReactComponent as SpyIconActive} from '../../../assets/icons/spy.svg';
import {ReactComponent as KeyIcon} from '../../../assets/icons/key-password-border.svg';
import {ReactComponent as KeyIconActive} from '../../../assets/icons/key-password.svg';
import {ReactComponent as BugIcon} from '../../../assets/icons/bug.svg';

const AdminSidebarMenu: FunctionComponent = () => {
    return (
        <SidebarMenu>
            <SidebarMenuItem exact icon={ContactsIcon} activeIcon={ContactsIconActive}
                             to={route('admin-dashboard')}>Meldkamers</SidebarMenuItem>
            <SidebarMenuItem icon={ListIcon} activeIcon={ListIconActive}
                             to={route('admin-forms')}>Formulieren</SidebarMenuItem>
            <SidebarMenuItem exact icon={UserIcon} activeIcon={UserIconActive}
                             to={route('admin-superadmins')}>Superbeheerders</SidebarMenuItem>
            <SidebarMenuItem exact icon={SpyIcon} activeIcon={SpyIconActive}
                             to={route('admin-events')}>Logs</SidebarMenuItem>
            <SidebarMenuItem exact icon={KeyIcon} activeIcon={KeyIconActive}
                             to={route('admin-logins')}>Inlogpogingen</SidebarMenuItem>
            <SidebarMenuItem exact icon={BugIcon} activeIcon={BugIcon}
                            to={route('rapports')}>Bug meldingen</SidebarMenuItem>
        </SidebarMenu>
    )
}

export default AdminSidebarMenu;
