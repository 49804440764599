import React, {FunctionComponent, useEffect, useState} from "react";
import BoxHeader from "../../../partials/box/BoxHeader";
import BoxContent from "../../../partials/box/BoxContent";
import Box from "../../../partials/box/Box";

interface Props {
    finished: number;
    total: number;
}

const CenterProgressItem: FunctionComponent<Props> = ({finished, total}) => {

    const [percent, setPercent] = useState<number>(0);

    useEffect(() => {
        const calc = (finished / total) * 100;
        setPercent((isNaN(calc)) ? 0 : Math.round(calc));
    }, []);

    return (
        <Box>
            <BoxHeader>
                Voortgang meldkamer
            </BoxHeader>
            <BoxContent emptyText="Geen data voor deze maand">
                <div className="dashboard-page__center-progress">
                    <div className="dashboard-page__center-progress__progress-bar">
                        <div className="dashboard-page__center-progress__progress-bar__bar-overflow">
                            <h1>{percent}%</h1>
                            <span>Afgerond</span>
                            <div className="dashboard-page__center-progress__progress-bar__bar"
                                style={{transform: 'rotate(' + (45+(percent*1.8)) + 'deg)'}}/>
                        </div>
                    </div>
                    <div className="dashboard-page__center-progress__stats">
                        <div className="dashboard-page__center-progress__stats__item">
                            <span className="systembase--black">Afgerond</span>
                            <div className="dashboard-page__center-progress__stats__item__wrapper">
                                <div
                                    className="dashboard-page__center-progress__stats__item__circle dashboard-page__center-progress__stats__item__circle--blue"/>
                                <div>
                                    <h3 className="heading3--black">{finished}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-page__center-progress__stats__item">
                            <span className="systembase--black">To-do</span>
                            <div className="dashboard-page__center-progress__stats__item__wrapper">
                                <div
                                    className="dashboard-page__center-progress__stats__item__circle dashboard-page__center-progress__stats__item__circle--grey"/>
                                <div>
                                    <h3 className="heading3--black">{total - finished}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BoxContent>
        </Box>
    )
}

export default CenterProgressItem;
