import React, {FunctionComponent} from 'react';

import FormPart from '../../../../../networking/models/FormPart';

import Box from 'components/client/partials/box/Box';
import BoxHeader from '../../box/BoxHeader';
import BoxContent from '../../box/BoxContent';
import FormPartQuestion from './FormPartQuestion';

export interface Props {
    partIndex: number,
    currentPart?: any,
    part: FormPart,
    currentQuestion?: any;
    onAnswerChange?: (part: number, question: number, answer: number) => void;
    onAnswerClick?: (part: number, question: number, answer: number) => void;
    readonly?: boolean;
    showType?: boolean;
}

const FormQuestionBox: FunctionComponent<Props> = React.memo(({ onAnswerClick, partIndex, currentPart, part,
                                                       currentQuestion, onAnswerChange, readonly, showType }) => {

    return (
        <Box>  
            <BoxHeader active={currentPart === partIndex}>
                {part.title}
            </BoxHeader>

            <BoxContent>
                {(part.questions ?? []).map((question, index) => {
                    const active: boolean = currentPart === partIndex && currentQuestion === index;

                    return (
                        <FormPartQuestion
                            question={question}
                            part={partIndex}
                            questionIndex={index}
                            onAnswerChange={onAnswerChange ?? undefined}
                            active={active}
                            readonly={readonly ?? false}
                            key={question.id ?? index}
                            onClick={onAnswerClick}
                            showType={showType} />
                    );
                })}
            </BoxContent>
        </Box>
    );
});

export default FormQuestionBox
