import React, { FunctionComponent, useEffect, useState } from 'react';

import ProgressItem from './ProgressItem';

interface Props {
  currentStage: number;
  totalStages: number;
}

const Progress: FunctionComponent<Props> = ({ currentStage, totalStages }) => {
  const [bars, setBars] = useState<Array<React.ReactNode>>();

  useEffect(() => {
    let items = [];
    
    for(let i = 0; i < totalStages; i++) {
      items.push(<ProgressItem key={i} active={currentStage === i}/>);
    }

    setBars(items);
  }, [currentStage, totalStages]);

  return (
    <div className="auth__registar__progress__container">
      {bars}
    </div>
  );
};

export default Progress;