import {RouteContainer} from 'buro-lib-ts';

import CenterRouteType from './RouteType';
import CentersPage from '../components/admin/pages/centers/CentersPage';
import CenterDetailPage from '../components/admin/pages/centers/CenterDetailPage';
import FormsPage from '../components/admin/pages/forms/FormsPage';
import SuperAdmins from '../components/admin/pages/superadmins/SuperAdmins';
import EventsPage from '../components/admin/pages/events/EventsPage';
import LoginsPage from '../components/admin/pages/logins/LoginsPage';
import FormDetail from '../components/admin/pages/forms/FormDetail/FormDetail';
import UserFeedback from 'components/admin/pages/user_feedback/UserFeedback';
import CenterAdmins from 'components/admin/pages/centers/CenterAdmins';

const routeContainer: RouteContainer = new RouteContainer();

routeContainer.group('/admin', () => {
    routeContainer.add('/', CentersPage, 'admin-dashboard');
    routeContainer.add('/centers/:id', CenterDetailPage, 'admin-center-detail');
    routeContainer.add('/centers/:id/center-admins', CenterAdmins, 'admin-center-admins');
    routeContainer.add('/forms', FormsPage, 'admin-forms');
    routeContainer.add('/forms/:id', FormDetail, 'admin-form-detail');
    routeContainer.add('/superadmins', SuperAdmins, 'admin-superadmins');
    routeContainer.add('/events', EventsPage, 'admin-events');
    routeContainer.add('/logins', LoginsPage, 'admin-logins');
    routeContainer.add('/rapports', UserFeedback, 'rapports');
}, CenterRouteType.ADMIN);

export default routeContainer.getRoutes();
