import React, { FunctionComponent } from 'react';

import { Tab as DefaultTab, TabProps } from 'buro-lib-ts';


const Tab: FunctionComponent<TabProps> = ({ children, index, onClick, active }) => {
    return (
      <DefaultTab
          className={'tab-navigation__tab'}
          activeClassName={'tab-navigation__tab tab-navigation__tab--active'}
          index={index}
          onClick={onClick}
          active={active}>
          { children }
      </DefaultTab>
    );
};

export default Tab;
