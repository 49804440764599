import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'components/contexts/AuthContext';

import logoRegular from 'assets/logos/logo-regular.png';
import logoWhite from 'assets/logos/logo-white.png';

import AuthDropdown from './AuthDropdown';
import {DarkTheme} from '../../../themes/theme';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {}

const NavBar: FunctionComponent<Props> = () => {
    const { user } = useAuth();
    const { theme } = useTheme();

    return (
        <nav className={'nav nav--' + theme.modifier}>
            <div className="nav__content">
                <div className="nav__left">
                    <Link to={'/'}>
                        <img src={theme.name === DarkTheme.name ? logoWhite : logoRegular} className={'nav__logo'} alt={'Logo'} />
                    </Link>
                    <div className="nav__item">
                        <p className="nav__title">Meldkamercoach</p>
                        <p className="nav__center">{user?.center_name}</p>

                    </div>
                </div>

                <div className="nav__right">
                    <AuthDropdown />
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
