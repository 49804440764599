import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {route} from 'buro-lib-ts';

import {Link} from 'react-router-dom';

import DateInput from 'components/client/partials/input/DateInput';

import Box from 'components/client/partials/box/Box';
import BoxHeader from 'components/client/partials/box/BoxHeader';
import BoxContent from 'components/client/partials/box/BoxContent';

import DashboardModel from '../../../../networking/models/Dashboard';

import AuditItem from './boxes/AuditItem';
import EmployeeProgressItem from './boxes/EmployeeProgressItem';

import {AuthContext} from 'components/contexts/AuthContext';
import CenterProgressItem from "./boxes/CenterProgressItem";
import {isDev} from "../../../../helpers/Env";

interface Props {
    data: DashboardModel;
    month: string;
    setMonth: (month: string) => void;
}

const DashboardContent: FunctionComponent<Props> = (props) => {
    const {isCenterAdmin} = useContext(AuthContext);

    const [data, setData] = useState<DashboardModel>();
    const [percent, setPercent] = useState(0);

    const progressBar = useRef(null);
    const circle = useRef(null);
    const circleContent = useRef(null);

    useEffect(() => {
        const result = props.data;

        setData(result);

        const calc = (result.center_audit_stats.finished / result.center_audit_stats.total) * 100;
        let percentage: number = (isNaN(calc)) ? 0 : Math.round(calc);

        setPercent(percentage);

        if (progressBar && progressBar.current) {
            // @ts-ignore: Object is possibly 'null'.
            progressBar.current.style.width = ((result.user_audit_stats.finished / result.user_audit_stats.total) * 100) + '%';
        }

        if (circle && circle.current) {
            if (circleContent && circleContent.current) {
                let degrees = (1.8 * percentage) + 45;
                // @ts-ignore: Object is possibly 'null'.
                circle.current.style.transform = `rotate(${degrees}deg)`;
                // @ts-ignore: Object is possibly 'null'.
                circleContent.current.style.transform = `translate(-50%, -50%) rotate(-${degrees}deg)`;
            }
        }
    }, [props.data, setData, setPercent]);

    const changeMonth = (value: string) => {
        props.setMonth(value);
    }

    return (
        <>
            {isDev() && <div className="top-page-error">
                <h2>Testomgeving</h2>
                <span>U bevindt zich momenteel in een testomgeving. Dit betekent dat hier geen echte informatie te vinden is. Daarnaast zullen gegevens die u aanmaakt of wijzigt voor een bepaalde tijd opgeslagen blijven.</span>
            </div>}
            <div className="dashboard-page dashboard-page__content">
                {(!data) ? null :
                    <React.Fragment>
                        <div
                            className="dashboard-page__column dashboard-page__column--small dashboard-page__center-admin">
                            <h1 className="heading2--black">Welkom, <br/>{data.name}</h1>

                            <h5 className="heading5--black">Meldkamer</h5>
                            <p className="systembase--black">U bent ingelogd bij meldkamer {data.center}.</p>

                            <table className="dashboard-page__center-employees">
                                <tbody>
                                <tr className="heading5--black">
                                    <td>Aantal Medewerkers</td>
                                    <td>{data.center_employees_stats.total}</td>
                                </tr>
                                {data.center_employees_stats.roles.map((item) =>
                                    <tr key={item.name}>
                                        <td className="systembase--black">{item.name}</td>
                                        <td className="systembase--black">{item.amount}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <div className="dashboard-page__center-employees__sub">
                                <sub>Een medewerker kan meerdere rollen hebben.</sub>
                            </div>
                        </div>
                        <div className="dashboard-page__column dashboard-page__column--wide dashboard-page__to-do">
                            <h4 className="heading4--black">To-do</h4>
                            <Box>
                                <BoxHeader>
                                <span
                                    className="box__header--active">{data.user_audit_stats.finished}</span> {(data.user_audit_stats.finished > 1) ? "audits" : "audit"} afgerond
                                    van de {data.user_audit_stats.total}
                                    <div className="progress-bar">
                                        <div className="progress-bar__bar" ref={progressBar}/>
                                    </div>
                                </BoxHeader>
                                <BoxContent emptyText="Geen audits om te toetsen">
                                    {data.audits.length <= 0 ? null :
                                        <div className="dashboard-page__audits">
                                            {data.audits.map((audit) => <AuditItem key={audit.id} user={data.name}
                                                                                   data={audit}/>)}
                                            <div className="dashboard-page__audits__show-everything"><Link
                                                to={route('audits-for-me')}>Toon alles</Link></div>
                                        </div>
                                    }
                                </BoxContent>
                            </Box>
                        </div>

                        <div className="dashboard-page__column--small dashboard-page__stats">
                            <h4 className="heading4--black">Statistieken</h4>
                            <div className="dashboard-page__month-select">
                                <DateInput onChange={changeMonth} default={props.month} type={'month'}/>
                            </div>
                            <CenterProgressItem finished={data.center_audit_stats.finished}
                                                total={data.center_audit_stats.total}/>
                            {isCenterAdmin() && (
                                <Box>
                                    <BoxHeader>
                                        Voortgang werknemers
                                    </BoxHeader>
                                    <BoxContent emptyText="Geen data voor deze maand">
                                        {data.employees_audit_stats.length <= 0 ? null :
                                            <div className="dashboard-page__employee-progress">
                                                {data.employees_audit_stats.map((employee) => <EmployeeProgressItem
                                                    key={employee.id} data={employee}/>)}
                                            </div>
                                        }
                                    </BoxContent>
                                </Box>
                            )}
                        </div>
                    </React.Fragment>}
            </div>
        </>
    );
};

export default DashboardContent;
