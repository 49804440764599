import React, {FunctionComponent, useContext, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';

import {useAuditTypeAuditorQueryConfig} from '../../../query/Audit/Query';
import {AuthContext} from "../../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import MyAudits from "./MyAudits";

interface Props {
}

const AuditsForMe: FunctionComponent<Props> = () => {

    const [auditConfig, page, setPage] = useAuditTypeAuditorQueryConfig({});
    const auditsQuery = useQuery(auditConfig);

    const {isAuditor} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuditor()) navigate('/dashboard');
    }, [isAuditor, navigate]);

    return (
        <>
            {
                auditsQuery &&
                <MyAudits title={'Voor mij'} audits={auditsQuery.data?.data ?? []} total={auditsQuery.data?.total ?? 0}
                          paginationData={{
                              current: auditsQuery.data?.currentPage ?? 1,
                              max: auditsQuery.data?.lastPage ?? 1,
                              setPage: setPage
                          }}/>
            }
        </>
    );
};

export default AuditsForMe;
