import React, {FunctionComponent, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {TabContent, useForm, route, input, check} from 'buro-lib-ts';

import Page from '../../../layout/Page';
import Tabs from 'components/layout/tabs/Tabs';
import SearchInput from '../../../client/partials/input/SearchInput';
import Button from '../../../layout/buttons/Button';
import Form from '../../../../networking/models/Form';
import AuditFormList from '../../partials/form/AuditFormList';
import Modal, { useModal } from '../../../client/partials/modal/Modal';
import CancelButton from '../../../layout/buttons/CancelButton';
import Input from '../../../client/partials/input/Input';
import {success} from '../../../../helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {useFormIndexQueryConfig} from '../../../query/Form/Form';
import {useQuery} from '@tanstack/react-query';
import {FormState} from '../../../form/Form';
import {useFormMutations} from '../../../query/Form/Mutation';
import {validateNumber} from "../../../../helpers/Validation";
import ConfirmModal from "../../../client/partials/modal/ConfirmModal";
import Checkbox from 'components/client/partials/input/Checkbox';

const validateMaxErrorCountInput = (value: string) => {
    return validateNumber(value, 0, 99);
};

// TODO: TEST EVERY QUERY ACTION!
const FormsPage: FunctionComponent = () => {
    const navigate = useNavigate();

    const [formConfig] = useFormIndexQueryConfig();
    const formQuery = useQuery(formConfig);

    const { createForm, updateForm, deleteForm } = useFormMutations();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedForm, setSelectedForm] = useState<Form>();
    const [modalOpen, modalActive, toggleModal] = useModal();
    const [deleteModalOpen, deleteModalActive, toggleDeleteModal] = useModal();

    const [formState, onFormStateChange] = useForm<FormState>({
        name: input(''),
        max_error_count: input('', [validateMaxErrorCountInput]),
        max_knockout_count: input('', [validateMaxErrorCountInput]),
        draft: check(false),
    });

    const onFormSave = () => {
        if(formState.values.id) {
            updateForm.mutateAsync(formState.values)
                .catch(logError);
        } else {
            createForm.mutateAsync(formState.values)
                .then(form => navigate(route('admin-form-detail', form)))
                .catch(logError);
        }

        resetNewForm();
        toggleModal();
    };

    const onFormClick = (form: Form) => {
        navigate(route('admin-form-detail', form));
    };

    const onFormEditClick = (form: Form) => {
        formState.set({
            id: form.id,
            name: form.name,
            max_knockout_count: form.max_knockout_count.toString(),
            max_error_count: form.max_error_count.toString(),
            draft: form.draft
        });

        toggleModal();
    };

    const onFormDeleteClick = async () => {
        if (selectedForm != null) {
            deleteForm.mutateAsync(selectedForm.id)
                .then(() => success('Formulier is verwijderd!'))
                .catch(logError);
            toggleDeleteModal();
        }
    };

    const getFilteredForms = (): Form[] => {
        const forms = formQuery.data ?? [];

        if(searchQuery === '') {
            return forms;
        }

        return forms.filter((form) => form.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1);
    };

    const closeModal = () => {
        resetNewForm();
        toggleModal();
    };

    const resetNewForm = () => {
        formState.set({
            id: undefined,
            name: '',
            max_knockout_count: '',
            max_error_count: ''
        });
    };

    return (
        <Page className={'admin-forms-page'}>
            <Tabs noErrors deps={[formQuery.data, searchQuery]}>
                <TabContent>
                    <h2 className={'page__title'}>Vragenlijsten</h2>

                    <div className={'page__search flex flex--justify-between'}>
                        <SearchInput onSearch={setSearchQuery} value={searchQuery} />

                        <Button onClick={toggleModal}>Nieuwe vragenlijst</Button>
                    </div>
                    
                    <AuditFormList
                        forms={getFilteredForms()}
                        onFormClick={onFormClick}
                        onFormEditClick={onFormEditClick}
                        setSelectedForm={setSelectedForm}
                        toggleDeleteModal={toggleDeleteModal} />
                </TabContent>
            </Tabs>

            <ConfirmModal isOpen={deleteModalOpen} isActive={deleteModalActive} onCloseClick={toggleDeleteModal}
                          onDeleteConfirm={onFormDeleteClick} confirmObjectName={'vragenlijst'}/>

            <Modal isOpen={modalOpen} active={modalActive} onCloseClick={closeModal} style={{ width: '40%', height: '50%' }} className={'admin-forms-page__modal'}>
                <h3>Nieuw formulier</h3>

                <Input
                    id={'name'}
                    text={'Naam'}
                    form={formState}
                    onChange={onFormStateChange} />

                <Input
                    id={'max_error_count'}
                    text={'Max. herhaling foutieve noodzakelijke criteria'}
                    form={formState}
                    onChange={onFormStateChange} />

                <Input
                    id={'max_knockout_count'}
                    text={'Max. herhaling foutieve verplichte criteria'}
                    form={formState}
                    onChange={onFormStateChange} />

                <Checkbox checked={formState.values.draft} onChange={(draft) => {
                    formState.set({ draft });
                }} text='Concept' />

                <div className={'admin-forms-page__modal-actions'}>
                    <CancelButton onClick={closeModal}>Annuleren</CancelButton>

                    <Button onClick={onFormSave}>Opslaan</Button>
                </div>
            </Modal>
        </Page>
    );
};

export default FormsPage;
