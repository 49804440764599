import {App} from 'buro-lib-ts';
import {getEnvUrl} from '../helpers/Env';
import AuthFilter from '../networking/filters/AuthFilter';
import routes from '../routing/Routes';

class AuditToolApp extends App {

    setup(): void {
        this.setupRequester(getEnvUrl(), [AuthFilter]);
        this.setupTranslator({ nl: {} });
        this.setupRoutingConfig({ routes });
    }
}

export default AuditToolApp;
