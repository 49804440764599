import React, {FunctionComponent, useState} from 'react';

import {FormAccessor, OnFormChangeCallback} from 'buro-lib-ts';

import User from '../../../../../networking/models/User';
import Form from '../../../../../networking/models/Form';
import Input from '../../input/Input';
import Select, {SelectOption} from '../../input/select/Select';
import CancelButton from '../../../../layout/buttons/CancelButton';
import Button from '../../../../layout/buttons/Button';

// TODO: remove all if(!selectData.auditorId because ?: have been removed
export interface SelectTabData {
    auditorId: number;
    callerId: number;
    formId: number;
    auditCallCount: string;
    requiredAuditCalls: string;
}

interface Props {
    forms: Form[];
    triagists?: User[];
    auditors?: User[];
    active: boolean;

    selectForm: FormAccessor<SelectTabData>;
    onSelectFormChange: OnFormChangeCallback<SelectTabData>;

    onCancelClick: () => void;
    onNextClick: (form: FormAccessor<SelectTabData>) => void;
}

// TODO: TEST form change
const SelectTab: FunctionComponent<Props> = (props) => {

    const {forms, triagists, auditors, active, selectForm, onSelectFormChange} = props;

    const [showErrors, setShowErrors] = useState<boolean>(false);

    const renderUserOption = (user: User) => {
        return user.first_name + ' ' + user.last_name;
    };

    const onAuditorSelect = (auditor: User) => {
        selectForm.set({
            auditorId: auditor !== undefined ? auditor.id : undefined,
        });
    };

    const onFormSelect = (form: Form) => {
        selectForm.set({
            formId: form !== undefined ? form.id : undefined,
        });
    };

    const onTriagistSelect = (triagist: User) => {
        selectForm.set({
            callerId: triagist !== undefined ? triagist.id : undefined,
        });
    };

    const onUserSearch = (search: string, options: SelectOption[]): SelectOption[] => {
        return options.filter((option) => {
            const u = option.value;

            return `${u.first_name} ${u.last_name}`.indexOf(search) > -1 || `${u.first_name.toLowerCase()} ${u.last_name.toLowerCase()}`.indexOf(search) > -1
        });
    };

    const getErrors = () => {
        const errors: string[] = [];
        if (!selectForm.values.auditorId || selectForm.values.auditorId === 0) {
            errors.push('Kies een auditor');
        }
        if (!selectForm.values.formId || selectForm.values.formId === 0) {
            errors.push('Kies een formulier');
        }

        if (!selectForm.values.callerId || selectForm.values.callerId === 0) {
            errors.push('Kies een triagist');
        }

        if (Number(selectForm.values.requiredAuditCalls) > Number(selectForm.values.auditCallCount)) {
            errors.push('Het aantal verplichte conversaties mag niet groter zijn dan het aantal conversaties');
        }

        selectForm.errors.auditCallCount?.forEach((error) => {
            errors.push("Conversaties " + error.key.toLowerCase());
        });

        selectForm.errors.requiredAuditCalls?.forEach((error) => {
            errors.push("Minimale afgeronde conversaties " + error.key.toLowerCase());
        });


        return errors;
    };

    if (!active) return null;

    const selectedAuditor = auditors?.findIndex((a) => a.id === selectForm.values.auditorId);
    const selectedOperator = triagists?.findIndex((o) => o.id === selectForm.values.callerId);
    const selectedForm = forms.findIndex((f) => f.id === selectForm.values.formId);

    return (
        <>
            <div className={'audit-create-box__select-tab'}>
                <p className={'audit-create-box__title'}>Audit gegevens</p>

                <div className={'audit-create-box__select'}>
                    <Select
                        title={'Kies auditor'}
                        options={auditors || []}
                        onSelect={onAuditorSelect}
                        renderOption={renderUserOption}
                        onSearch={onUserSearch}
                        selected={selectedAuditor}/>

                    <Select
                        title={'Kies formulier'}
                        options={forms}
                        onSelect={onFormSelect}
                        renderOption={(form) => form.name}
                        selected={selectedForm}/>
                </div>

                <div className={'audit-create-box__select'}>
                    <Select
                        title={'Kies triagist'}
                        options={triagists || []}
                        onSelect={onTriagistSelect}
                        renderOption={renderUserOption}
                        onSearch={onUserSearch}
                        selected={selectedOperator}/>

                    <Input
                        id={'auditCallCount'}
                        text={'Conversaties (1 - 8)'}
                        size={'small'}
                        form={selectForm}
                        onChange={onSelectFormChange}/>
                </div>

                <div className={'audit-create-box__select'}>
                    <Input
                        id={'requiredAuditCalls'}
                        text={'Minimale afgeronde conversaties (0 - 8)'}
                        size={'small'}
                        form={selectForm}
                        onChange={onSelectFormChange}/>
                </div>
            </div>


            <ul className={'audit-create-box__errors'}>
                {getErrors().map((error, i) => <li key={i}>{error}</li>)}
            </ul>


            <div className={'audit-create-box__controls'}>
                <CancelButton onClick={props.onCancelClick}>Annuleren</CancelButton>
                <Button
                    onClick={() => props.onNextClick(selectForm)}
                    disabled={!selectForm.isValid || getErrors().length > 0}
                >Volgende</Button>
            </div>
        </>
    );
};

export default SelectTab;
