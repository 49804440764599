import React, {FunctionComponent, useEffect, useState} from 'react';
import {TabContent} from 'buro-lib-ts';

import Page from 'components/layout/Page';

import Tabs from 'components/layout/tabs/Tabs';
import TabBar from 'components/layout/tabs/TabBar';
import Tab from 'components/layout/tabs/Tab';

import {success} from '../../../../helpers/Toast';
import Modal, {useModal} from '../../partials/modal/Modal';
import AuditDataBox from '../../partials/audits/create/AuditDataBox';
import {useUserRoleAuditorQueryConfig, useUserRoleOperatorQueryConfig} from "../../../query/User/Query";
import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {
    useAuditTypeFailedQueryConfig,
    useAuditTypeOpenQueryConfig,
    useAuditTypePassedQueryConfig
} from "../../../query/Audit/Query";
import {AuditIndexRequest} from "../../../../networking/requests/Audit";
import AuditTab from "./tab/AuditTab";

export interface AuditFilters extends AuditIndexRequest {
    periodIndex?: number;
}

const toAuditIndexRequest = (filters: AuditFilters): AuditIndexRequest => {
    return {
        auditorId: filters.auditorId,
        operatorId: filters.operatorId,
        startDate: filters.startDate,
        endDate: filters.endDate
    };
}

const Audits: FunctionComponent = () => {

    const [modalOpen, modalActive, toggleModal] = useModal();

    const onAuditCreate = () => {
        success('Audit is aangemaakt!');
    };

    const {user} = useAuth();
    const center = user?.center!;

    const [filters, setFilters] = useState<AuditFilters>({});

    const [auditorsConfig] = useUserRoleAuditorQueryConfig(center);
    const [operatorsConfig] = useUserRoleOperatorQueryConfig(center);
    const [openAuditsConfig, openPage, setOpenPage] = useAuditTypeOpenQueryConfig(toAuditIndexRequest(filters));
    const [passedAuditsConfig, passedPage, setPassedPage] = useAuditTypePassedQueryConfig(toAuditIndexRequest(filters));
    const [failedAuditsConfig, failedPage, setFailedPage] = useAuditTypeFailedQueryConfig(toAuditIndexRequest(filters));
    const auditors = useQuery(auditorsConfig);
    const operators = useQuery(operatorsConfig);
    const openAudits = useQuery(openAuditsConfig);
    const passedAudits = useQuery(passedAuditsConfig);
    const failedAudits = useQuery(failedAuditsConfig);

    useEffect(() => {
        setPage(1);
    }, [filters]);

    const setPage = (page: number) => {
        setOpenPage(page);
        setPassedPage(page);
        setFailedPage(page);
    }

    // TODO: Remove deps from Tabs
    return (
        <Page className={`audits-page`}>
            <Tabs scrollable>
                <TabBar>
                    <Tab>Openstaand</Tab>
                    <Tab>Op/boven de norm</Tab>
                    <Tab>Onder de norm</Tab>
                </TabBar>

                <TabContent>
                    {
                        openAudits &&
                        <AuditTab
                            title={'Openstaande audits'}
                            audits={openAudits.data?.data ?? []}
                            filters={filters}
                            setFilters={setFilters}
                            onAddClick={toggleModal}
                            auditors={auditors.data || []}
                            operators={operators.data || []}
                            paginationData={{
                                current: openAudits.data?.currentPage ?? 1,
                                max: openAudits.data?.lastPage ?? 1,
                                setPage: setOpenPage
                            }}
                        />
                    }
                </TabContent>

                <TabContent>
                    {
                        passedAudits &&
                        <AuditTab
                            title={'Audits op/boven de norm'}
                            audits={passedAudits.data?.data ?? []}
                            filters={filters}
                            setFilters={setFilters}
                            onAddClick={toggleModal}
                            auditors={auditors.data || []}
                            operators={operators.data || []}
                            paginationData={{
                                current: passedAudits.data?.currentPage ?? 1,
                                max: passedAudits.data?.lastPage ?? 1,
                                setPage: setPassedPage
                            }}/>
                    }
                </TabContent>

                <TabContent>
                    {
                        failedAudits &&
                        <AuditTab
                            title={'Audits onder de norm'}
                            audits={failedAudits.data?.data ?? []}
                            filters={filters}
                            setFilters={setFilters}
                            onAddClick={toggleModal}
                            auditors={auditors.data || []}
                            operators={operators.data || []}
                            paginationData={{
                                current: failedAudits.data?.currentPage ?? 1,
                                max: failedAudits.data?.lastPage ?? 1,
                                setPage: setFailedPage
                            }}/>
                    }
                </TabContent>
            </Tabs>

            <Modal isOpen={modalOpen} active={modalActive} onCloseClick={toggleModal}
                   className={'audit-create-box__modal'} customBody={true}>

                <AuditDataBox onCancelClick={toggleModal} onAuditCreate={onAuditCreate}/>
            </Modal>
        </Page>
    );
};

export default Audits;
