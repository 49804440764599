import {RouteContainer} from 'buro-lib-ts';

import LoginSSO from '../components/client/pages/auth/LoginSSO';
import Landing from '../components/client/pages/auth/Landing';
import Login from '../components/client/pages/auth/Login';
import PasswordReset from '../components/client/pages/auth/password/PasswordReset';
import NewPassword from '../components/client/pages/auth/password/NewPassword';
import Register from '../components/client/pages/auth/register/Register';

const routeContainer: RouteContainer = new RouteContainer();

routeContainer.add('/login/sso/:center_abbreviation',LoginSSO, 'login-sso');
routeContainer.add('/', Landing,'landing');
routeContainer.add('/meldkamer', Landing,'landing');
routeContainer.add('/login', Login,'login');
routeContainer.add('/password-reset',PasswordReset, 'reset-password');
routeContainer.add('/password-reset/reset/:email/:token', NewPassword, 'new-password');
routeContainer.add('/register', Register, 'register');
routeContainer.add('/finish-registration/:token', Register, 'register-with-token');

export default routeContainer.getRoutes();
