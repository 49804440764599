import React, {FunctionComponent, useState} from 'react';
import {FormAccessor} from 'buro-lib-ts';

import {useAuth} from "../../../contexts/AuthContext";

import SearchInput from 'components/client/partials/input/SearchInput';
import Button from 'components/layout/buttons/Button';
import UserList from 'components/client/partials/users/UserList';
import User from 'networking/models/User';
import Modal, {useModal} from 'components/client/partials/modal/Modal';
import {UserForm, useUserForm} from '../../../form/User';
import UserModal from '../../partials/users/UserModal';
import {warn} from "../../../../helpers/Toast";
import ConfirmModal from "../../partials/modal/ConfirmModal";


interface Props {
    tabId: number;
    title: string;
    users: User[];
    searchQuery: string;
    onSearch: (tab: number, query: string) => void;
    onUserSoftDelete: (user: User) => Promise<void>;
    onUserCreate: (user: UserForm, roles: string[]) => Promise<void>;
    onUserUpdate: (user: User, data: UserForm, roles: string[]) => Promise<void> | void;
    onUserRestore: (user: User) => Promise<void>;
}

const UsersTab: FunctionComponent<Props> = ({
                                                onUserSoftDelete,
                                                onUserCreate,
                                                onUserUpdate,
                                                onUserRestore,
                                                tabId,
                                                title,
                                                users,
                                                searchQuery,
                                                onSearch
                                            }) => {

    const {isCenterAdmin, isSuperAdmin} = useAuth();

    const [form, onFormChange] = useUserForm();

    const [modalOpen, modalActive, toggleModal] = useModal();
    const [blockModalOpen, blockModalActive, blockModalToggle] = useModal()
    const [restoreModalOpen, restoreModalActive, restoreModalToggle] = useModal()

    const [selectedUser, setSelectedUser] = useState<User>();

    const openModal = (user?: User) => {
        if (user) {
            form.set(user);
            setSelectedUser(user);
        }

        toggleModal();
    };

    const closeModal = () => {
        form.reset();
        toggleModal();
    };

    const onSearchChange = (searchQuery: string) => {
        onSearch(tabId, searchQuery);
    };

    const onAddUserClick = () => {
        setSelectedUser(undefined);
        toggleModal();
    };

    const onSoftDelete = () => {
        if (selectedUser != null)
            onUserSoftDelete(selectedUser).then(r => console.log(r))
        blockModalToggle()
    }

    const onUserDeblock = () => {
        if (selectedUser != null)
            onUserRestore(selectedUser).then(r => console.log(r))
        restoreModalToggle()
    }

    const onUserSaveClick = async (form: FormAccessor<UserForm>, roles: string[]) => {
        if (!isSuperAdmin() && roles.length === 0) {
            warn("Selecteer minimaal 1 rol");

            return;
        }
        if (!form.isValid) return;

        selectedUser
            ? await onUserUpdate(selectedUser, form.values, roles)
            : await onUserCreate(form.values, roles);

        closeModal();
    };

    return (
        <React.Fragment>
            <h2 className="users-page__title">{title} ({users.length})</h2>

            <div className="users-page__search">
                <SearchInput onSearch={onSearchChange} value={searchQuery}/>
                {(isCenterAdmin() || isSuperAdmin()) && <Button onClick={onAddUserClick}>Nieuwe gebruiker</Button>}
            </div>

            {
                !isSuperAdmin() &&
                <p className="users-page__hint">Klik op de naam van de auditor om alle audits in te zien</p>
            }

            <UserList
                users={users}
                openModal={openModal}
                setSelectedUser={setSelectedUser}
                blockModalToggle={blockModalToggle}
                restoreModalToggle={restoreModalToggle}/>

            <Modal isOpen={modalOpen} active={modalActive} onCloseClick={closeModal} className="user-modal">
                <UserModal
                    user={selectedUser}
                    form={form}
                    onFormChange={onFormChange}
                    closeModal={closeModal}
                    onSaveClick={onUserSaveClick}
                />
            </Modal>
            <ConfirmModal isOpen={blockModalOpen} isActive={blockModalActive} onCloseClick={blockModalToggle}
                          onDeleteConfirm={onSoftDelete} confirmObjectName={'gebruiker'}
                          confirmObjectAction={'blokkeren'}/>
            <ConfirmModal isOpen={restoreModalOpen} isActive={restoreModalActive} onCloseClick={restoreModalToggle}
                          onDeleteConfirm={onUserDeblock} confirmObjectName={'gebruiker'}
                          confirmObjectAction={'deblokkeren'}/>
        </React.Fragment>
    );
};

export default UsersTab;
