import React, {FunctionComponent} from 'react'
import {Route} from 'react-router-dom';

import AuthHeader from 'components/client/pages/auth/layout/AuthHeader';

import moment from 'moment';
import {routeMap} from '../../routing/Routes';
import {RouteSwitcher} from 'buro-lib-ts';
import Landing from "../client/pages/auth/Landing";

interface Props {
}

const Auth: FunctionComponent<Props> = () => {

    return (
        <div className="auth">
            <AuthHeader/>

            <RouteSwitcher>
                {routeMap.auth.map(route =>
                    <Route key={route.path} path={route.path} element={<route.component/>}/>
                )}
                <Route path={'*'} element={<Landing/>}/>
            </RouteSwitcher>

            <p className="auth__footer">Copyright {moment().format('Y')} - Alle rechten voorbehouden</p>
        </div>
    )
}

export default Auth;
