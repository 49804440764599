import React, {FunctionComponent, useState} from 'react';

import WayPoint from 'networking/models/WayPoint';

import WavePointInput from './WavePointInput';
import {useTheme} from '../../../../contexts/ThemeContext';

interface Props {
    wavePoints: WayPoint[];
    onInputSubmit: (wavePoint: WayPoint) => void;
    onWayPointCancel: (wayPoint: WayPoint) => void;
}

const WavePointInputList: FunctionComponent<Props> = ({ wavePoints, onInputSubmit, onWayPointCancel }) => {
    const { theme } = useTheme();

    const [inputValues, setInputValues] = useState<any>({});

    const onInputChange = (index: number, value: string) => {
        const wavePoint = wavePoints[index];

        const newInputValues = { ...inputValues };
        newInputValues[wavePoint.seconds] = value;

        setInputValues(newInputValues);
    };

    const onWavePointInputSubmit = (index: number) => {
        const wavePoint = wavePoints[index];

        const wavePointToSave = { ...wavePoint };
        wavePointToSave.comment = inputValues[wavePoint.seconds];

        deleteInputValue(wavePoint);

        onInputSubmit(wavePointToSave);
    }

    const onWayPointCancelClick = (index: number) => {
        deleteInputValue(wavePoints[index]);

        onWayPointCancel(wavePoints[index]);
    };

    const deleteInputValue = (wayPoint: WayPoint) => {
        const newInputValues: any = { ...inputValues };

        delete newInputValues[wayPoint.seconds];

        setInputValues(newInputValues);
    };

    return (
        <div className={`wave-point-input-list wave-point-input-list--${theme.modifier}`}>

            { wavePoints.map((wavePoint, index) => (
                <WavePointInput
                    wavePoint={wavePoint}
                    onInputChange={onInputChange}
                    onSubmit={onWavePointInputSubmit}
                    onCancel={onWayPointCancelClick}
                    value={inputValues[wavePoint.seconds] || ''}
                    index={index}
                    key={wavePoint.id ?? wavePoint.index} />
            )) }

        </div>
    );
};

export default WavePointInputList;
