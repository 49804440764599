import Admin from './Admin';
import Client from './Client';
import Auth from './Auth';

const routes = [...Client, ...Admin, ...Auth];

export const routeMap = {
    client: Client,
    admin: Admin,
    auth: Auth
};

export default routes;
