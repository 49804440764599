import React, { FunctionComponent, useState, FormEvent, ChangeEvent } from 'react';

import PasswordCheck from '../../../partials/auth/PasswordCheck';
import IconInput from '../../../partials/input/IconInput';
import Button from 'components/layout/buttons/Button';

import { ReactComponent as KeyPassword } from '../../../../../assets/icons/key-password.svg';

interface Props {
  handlePasswordsSubmit: (passwords: PasswordsObject) => void;
}

export interface PasswordsObject {
  password: string;
  password_confirmation: string;
}

const AddPassword: FunctionComponent<Props> = ({ handlePasswordsSubmit }) => {
  const [passwords] = useState<PasswordsObject>({} as PasswordsObject);
  const [exportPasswords, setExportPasswords] = useState<PasswordsObject>({} as PasswordsObject);

  //Password checking
  const [containsUL, setContainsUL] = useState<boolean>(false); // uppercase letter
  const [containsN, setContainsN] = useState<boolean>(false); // number
  const [containsSC, setContainsSC] = useState<boolean>(false); // special character
  const [contains8C, setContains8C] = useState<boolean>(false); // min 8 characters
  const [match, setMatch] = useState<boolean>(false);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setExportPasswords({ ...exportPasswords, 'password': e.target.value});
  };

  const handleOnKeyUp = () => {
    if(exportPasswords.password.length >= 8) {
      setContains8C(true);
    } else {
      setContains8C(false);
    }
    
    if(exportPasswords.password.toLowerCase() !== exportPasswords.password) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
    }

    if(/\d/.test(exportPasswords.password)) {
      setContainsN(true)
    } else {
      setContainsN(false);
    }

    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(exportPasswords.password)) {
      setContainsSC(true);
    } else {
      setContainsSC(false);
    }

    if(exportPasswords.password === exportPasswords.password_confirmation) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }

  const handleChangePasswordConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
    setExportPasswords({ ...exportPasswords, 'password_confirmation': e.target.value});
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const passwordsMatch = exportPasswords.password === exportPasswords.password_confirmation;
    
    if (containsUL && containsN && containsSC && contains8C && passwordsMatch) {
      handlePasswordsSubmit(exportPasswords);
    }
  }

  return (
    <div>
      <form className="auth__registar__form" onSubmit={handleSubmit}>
        <IconInput
          id="password"
          value={passwords.password}
          onChange={handleChangePassword}
          onKeyUp={handleOnKeyUp}
          icon={KeyPassword}
          label={true}
          labelName="Wachtwoord"
          password={true}
        />

        <div className="auth__registar__password-checks">
          <div className="auth__registar__password-checks__col">
            <PasswordCheck label={'Min. 8 karakters'} passed={contains8C} />
            <PasswordCheck label={'Min. 1 hoofdletter'} passed={containsUL} />
          </div>
          <div className="auth__registar__password-checks__col">
            <PasswordCheck label={'Min. 1 cijfer'} passed={containsN} />
            <PasswordCheck label={'Min. 1 speciaal teken'} passed={containsSC} />
          </div>
        </div>

        <IconInput
          id="password-confirmation"
          value={passwords.password_confirmation}
          onChange={handleChangePasswordConfirmation}
          onKeyUp={handleOnKeyUp}
          icon={KeyPassword}
          label={true}
          labelName="Wachtwoord herhalen"
          password={true}
        />
        
        <div className="auth__registar__password-checks">
          <PasswordCheck label={'Wachtwoorden komen overeen'} passed={match} />
        </div>

        <br /><br /><br />
        <Button disabled={!containsUL || !containsN || !containsSC || !contains8C || !match} className="button--right">Verder</Button>
      </form>
    </div>
  );
};

export default AddPassword;
