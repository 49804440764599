import React, {FormEvent, FunctionComponent} from 'react';

import Question from '../../../../../networking/models/Question';

import FormQuestionRadioInput from './FormQuestionRadioInput';
import FormQuestion from './FormQuestion';

interface Props {
    question: Question;
    part: number;
    questionIndex: number;
    onAnswerChange?: (part: number, question: number, answer: number) => void;
    onClick?: (part: number, question: number, answer: number) => void;
    active: boolean;
    readonly: boolean;
    showType?: boolean;
}

const FormPartQuestion: FunctionComponent<Props> = ({
                                                    onClick, question, part, questionIndex,
                                                    onAnswerChange, active, readonly, showType
                                                }) => {
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
    };

    const onRadioAnswerChange = (answer: number) => {
        if (!question.number) return;

        if (onAnswerChange)
            onAnswerChange(part, questionIndex, answer);
    };

    const onRadioAnswerClick = (answer: number) => {
        if (!question.number || !onClick) return;

        onClick(part, questionIndex, answer);
    };

    return (
        <FormQuestion question={question} active={active} showType={showType}>
            {!readonly &&
                <form onSubmit={onSubmit} className={'form-question__answers'}>
                    <FormQuestionRadioInput
                        checked={question.answer === 0}
                        value={0}
                        onChange={onRadioAnswerChange}
                        color={'green'}
                        onClick={onRadioAnswerClick}/>

                    <label className={'form-question__answer-label'}>Ja</label>

                    <FormQuestionRadioInput
                        checked={question.answer === 1}
                        value={1}
                        onChange={onRadioAnswerChange}
                        color={'red'}
                        onClick={onRadioAnswerClick}/>
                    <label className={'form-question__answer-label'}>Nee</label>

                    <FormQuestionRadioInput
                        checked={question.answer === 2}
                        value={2}
                        onChange={onRadioAnswerChange}
                        color={'orange'}
                        onClick={onRadioAnswerClick}/>
                    <label className={'form-question__answer-label'}>n.v.t</label>
                </form>
            }
        </FormQuestion>
    );
};

export default FormPartQuestion;
