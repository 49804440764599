import React, {FunctionComponent, useState} from 'react';

import {useAuth} from 'components/contexts/AuthContext';
import BackButton from '../buttons/BackButton';

import { Link } from 'react-router-dom';

import {ReactComponent as UserIcon} from 'assets/icons/icon-user-border.svg';
import {ReactComponent as HelpIcon} from 'assets/icons/help-border.svg';
import {ReactComponent as LogoutIcon} from 'assets/icons/logout-border.svg';
import {ReactComponent as ArrowIcon} from 'assets/icons/arrow.svg';

import Button from '../buttons/Button';
import { motion } from 'framer-motion';
import { success} from '../../../helpers/Toast';
import {useTheme} from '../../contexts/ThemeContext';

type Roles = 'operator' | 'auditor' | 'centeradmin';

const dropdownVariants = {
    closed: {
        height: 0
    },
    open: {
        height: '100%'
    }
};

const AuthDropdown: FunctionComponent = () => {
    const { logout, user, isAuditor, isOperator, isCenterAdmin, isCoach, retrieveGuides } = useAuth();
    const { theme } = useTheme();

    const [open, setOpen] = useState<boolean>(false);
    const [profileOpen, setProfileOpen] = useState<boolean>(false);
    const [profileHidden, setProfileHidden] = useState<boolean>(true);

    const profileCloseTime = 500;

    const toggleMenu = (): void => {
        setOpen(!open);
    };

    const toggleProfileMenu = (): void => {
        if (profileOpen) {
            setProfileOpen(false);

            setTimeout(() => {
                setProfileHidden(true);
            }, profileCloseTime);
        } else {
            setProfileHidden(false);

            setTimeout(() => {
                setProfileOpen(true);
            }, 0);
        }
    };

    const getMenuClassName = (): string => {
        let className = 'auth-dropdown__menu auth-dropdown__menu--' + (open ? 'open' : 'closed');

        if (profileOpen) {
            className += '  auth-dropdown__menu--left';
        }

        return className;
    };

    const getProfileMenuClassName = (): string => {
        return `
            auth-dropdown__profile 
            ${profileOpen ? 'auth-dropdown__profile--open' : ''} 
            ${profileHidden ? 'auth-dropdown__profile--hidden' : ''}`;
    };

    const userRolesHTML = user?.roles.map((role, index) => {
        return <span key={role.id}>{index === 0 ? role.name : ', ' + role.name.toLowerCase()}</span>;
    });

    const userRolesProfileHTML = user?.roles.map((role) => {
        return <p key={role.id} className={'auth-dropdown__info-text'}>{role.name}</p>;
    });

    const downloadGuide = async (role: Roles) => {

        const roleTranslation = {
            operator: 'triagist',
            auditor: 'auditor',
            centeradmin: 'beheerder'
        }

        retrieveGuides(role).then((res) => {
            downloadPdf('Handleiding ' + roleTranslation[role] + '.pdf', res.guide);
        }).catch((err) => {
            console.log(err);
        });
    }

    const downloadPdf = (filename: string, pdf: string) => {

        const source = `data:application/pdf;base64,${pdf}`;
        const downloadTag = document.createElement("a");

        downloadTag.href = source;
        downloadTag.download = filename;
        downloadTag.click();
    };

    const onLogoutClick = () => {
        logout();
        success('Je bent uitgelogd!');
    };

    return (
        <div className={'nav__item auth-dropdown auth-dropdown--' + theme.modifier} onClick={toggleMenu}>
            <div className="auth-dropdown__user">
                <span className={'auth-dropdown__user--main'}>{user?.first_name} {user?.last_name}</span>
                <span className={'auth-dropdown__user--sub'}>{userRolesHTML}</span>
            </div>

            <div className="auth-dropdown__toggle-button">
                <span>&#x25BE;</span>
            </div>

            <div
                className={getMenuClassName()} onClick={(e) => e.stopPropagation()}>
                <motion.div className={'auth-dropdown__menu-container'}
                    variants={dropdownVariants}
                    initial={'closed'}
                    animate={open ? 'open' : 'closed'}
                    transition={{ duration: .5 }}>
                    <div className="auth-dropdown__action" onClick={toggleProfileMenu}>
                        <UserIcon className={'auth-dropdown__icon auth-dropdown__icon--offset-right icon-grey'}/>
                        <span>Mijn profiel</span>

                        <div className="auth-dropdown__toggle-button">
                            <ArrowIcon className={'auth-dropdown__icon icon-grey icon-grey--filled'}/>
                        </div>
                    </div>

                    {(isOperator()) &&
                        <div className="auth-dropdown__action" onClick={() => downloadGuide('operator')}>
                            <HelpIcon className={'auth-dropdown__icon auth-dropdown__icon--offset-right icon-grey icon-grey--filled'}/>
                            <span>Help Triagist</span>
                        </div>
                    }

                    {(isCenterAdmin()) &&
                        <div className="auth-dropdown__action" onClick={() => downloadGuide('centeradmin')}>
                            <HelpIcon className={'auth-dropdown__icon auth-dropdown__icon--offset-right icon-grey icon-grey--filled'}/>
                            <span>Help Beheerder</span>
                        </div>
                    }

                    { ((isAuditor() || isCoach())) &&
                        <div className="auth-dropdown__action" onClick={() => downloadGuide('auditor')}>
                            <HelpIcon className={'auth-dropdown__icon auth-dropdown__icon--offset-right icon-grey icon-grey--filled'}/>
                            <span>Help Auditor</span>
                        </div>
                    }

                    <div className="auth-dropdown__action" onClick={onLogoutClick}>
                        <LogoutIcon
                            className={'auth-dropdown__icon auth-dropdown__icon--offset-right icon-grey icon-grey--filled'}/>
                        <span>Uitloggen</span>
                    </div>
                </motion.div>
            </div>

            <div className={getProfileMenuClassName()} onClick={e => e.stopPropagation()}>
                <BackButton onClick={toggleProfileMenu}>
                    Terug
                </BackButton>

                <div className={'auth-dropdown__profile-picture'}>
                    <span className={'auth-dropdown__'}>{user?.first_name + ' ' + user?.last_name}</span>
                </div>

                <div className="auth-dropdown__profile-info">
                    <div className="auth-dropdown__info-block">
                        <p className={'auth-dropdown__info-title'}>Rollen</p>
                        {userRolesProfileHTML}
                    </div>

                    <div className="auth-dropdown__info-block">
                        <p className={'auth-dropdown__info-title'}>E-mail</p>
                        <p className={'auth-dropdown__info-text'}>{user?.email}</p>
                    </div>

                    <div className="auth-dropdown__info-block">
                        <p className={'auth-dropdown__info-title'}>Actief sinds</p>
                        <p className={'auth-dropdown__info-text'}>{user?.created_at}</p>
                    </div>
                </div>

                <div className="auth-dropdown__profile-footer">
                    <Link to="/account">
                        <Button onClick={toggleMenu}>
                            Bewerken
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AuthDropdown;
