import React, {FunctionComponent, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { route, TabContent} from 'buro-lib-ts';

import FormPart from '../../../../../networking/models/FormPart';
import Tabs from '../../../../layout/tabs/Tabs';
import Form from '../../../../../networking/models/Form';
import TabBarNavigation from '../../../../layout/tabs/TabBarNavigation';
import BackButton from '../../../../layout/buttons/BackButton';
import TabBar from '../../../../layout/tabs/TabBar';
import TabContentLeft from '../../../../client/pages/conversations/form/TabContentLeft';
import TabContentRight from '../../../../client/pages/conversations/form/TabContentRight';
import BoxList from '../../../../client/partials/box/BoxList';
import Box from '../../../../client/partials/box/Box';
import BoxHeader from '../../../../client/partials/box/BoxHeader';
import BoxContent from '../../../../client/partials/box/BoxContent';
import Part from '../../../partials/form/Part';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import Question from '../../../../../networking/models/Question';
import {success} from '../../../../../helpers/Toast';
import QuestionModal from '../../../partials/form/QuestionModal';
import Modal, {useModal} from '../../../../client/partials/modal/Modal';
import PartModal from '../../../partials/form/PartModal';
import AddButton from '../../../layout/AddButton';
import ShortcutDroppable from '../../../partials/form/shortcut/ShortcutDroppable';
import {logError} from '../../../../utils/devtool/DevTool';
import {
    useFormReorderPartsMutation,
    useFormReorderQuestionsMutation,
} from '../../../../query/Form/Mutation';
import {useFormPartCreateMutation, useFormPartDeleteMutation } from '../../../../query/FormPart/Mutation';
import {useFormQuestionDeleteMutation, useFormQuestionCreateMutation} from '../../../../query/FormQuestion/Mutation';
import ConfirmModal from "../../../../client/partials/modal/ConfirmModal";
import Card from 'components/layout/Card';
import Checkbox from 'components/client/partials/input/Checkbox';
import { SubTitle } from 'chart.js';

interface SelectedQuestion {
    question: Question;
    number: number;
}

interface Props {
    form: Form;
    parts: FormPart[];
}

const FormDetailContent: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();

    const [boxRenderId, setBoxRenderId] = useState<number>(0);

    const [parts, setParts] = useState<FormPart[]>(props.parts);

    const [concept, setConcept] = useState(false);

    // Modal states
    const [selectedQuestion, setSelectedQuestion] = useState<SelectedQuestion>({
        question: { question: '', explanation: '' },
        number: -1
    });
    const [selectedPart, setSelectedPart] = useState<FormPart>({ title: '' } as FormPart);
    const [questionModalOpen, questionModalActive, toggleQuestionModal] = useModal();
    const [partModalOpen, partModalActive, togglePartModal] = useModal();

    const [deleteQuestionModalOpen, deleteQuestionModalActive, toggleDeleteQuestionModal] = useModal();
    const [deletePartModalOpen, deletePartModalActive, toggleDeletePartModal] = useModal();

    const formReorderPartsMutation = useFormReorderPartsMutation();
    const formReorderQuestionsMutation = useFormReorderQuestionsMutation();

    const formPartCreateMutation = useFormPartCreateMutation(props.form.id);
    const formPartDeleteMutation = useFormPartDeleteMutation(props.form.id);

    const formQuestionCreateMutation = useFormQuestionCreateMutation(props.form.id);
    const formQuestionDeleteMutation = useFormQuestionDeleteMutation(props.form.id);

    const onBackClick = () => {
        navigate(route('admin-forms'));
    };

    const recalculateBoxes = () => {
        setBoxRenderId(boxRenderId + 1);
    };

    const onQuestionSave = async (part: FormPart, question: Question) => {
        try {
            await formQuestionCreateMutation.mutateAsync({
                partId: part.id!,
                question: question,
            });

            success('Vraag is bijgewerkt!')
        } catch (e) {
            logError(e);
        }

        toggleQuestionModal();
    };

    const openQuestionModal = (part: FormPart, question: SelectedQuestion) => {
        setSelectedPart(part);
        setSelectedQuestion(question);
        toggleQuestionModal();
    };

    const onQuestionAddClick = (part: FormPart) => {
        openQuestionModal(part, { question: { question: '', explanation: '', order_id: part.questions!.length + 1 }, number: -1  });
    };

    const onQuestionEditClick = (part: FormPart, question: Question) => {
        openQuestionModal(part, { question, number: question.number! });
    };

    const onQuestionDeleteClick = async () => {
        const question = selectedQuestion.question;
        if  (question != null) {
            try {
                await formQuestionDeleteMutation.mutateAsync({
                    partId: question.part_id!,
                    question: question,
                });

                success('Vraag is verwijderd!');
            } catch (e: any) {
                logError(e);
            }
            toggleDeleteQuestionModal()
        }
    };

    const openPartModal = (part?: FormPart) => {
        setSelectedPart(part ?? { title: '' } as FormPart);
        togglePartModal();
    };

    const onPartSave = async (part: FormPart) => {
        try {
            await formPartCreateMutation.mutateAsync({ ...part, order_id: part.order_id ?? parts.length + 1 });

            recalculateBoxes();

            success('Fase is opgeslagen!');
        } catch (e: any) {
            logError(e);
        }

        togglePartModal();
    };

    const onPartEditClick = (part: FormPart) => {
        setSelectedPart(part);
        togglePartModal();
    };

    const onPartDeleteClick = async () => {
        if (selectedPart != null) {
            try {
                await formPartDeleteMutation.mutateAsync(selectedPart);

                success('Fase is verwijderd!');
            } catch (e: any) {
                logError(e);
            }
            toggleDeletePartModal()
        }
    };

    const recalculateQuestionNumbers = (parts: FormPart[]): FormPart[] => {
        let number = 1;

        for(let part = 0; part < parts.length; part++) {
            parts[part].order_id = part + 1;

            for(let question = 0; question < parts[part].questions!.length; question++) {
                parts[part].questions![question].number = number++;
            }
        }

        return parts;
    };

    const onQuestionDrop = (result: DropResult) => {
        if(!result.destination || result.reason === 'CANCEL') {
            return;
        }

        const dropSource = parts.findIndex(p => p.id! === parseInt(result.source.droppableId));
        const dropDestination =  parts.findIndex(p => p.id! === parseInt(result.destination!.droppableId));

        const newParts = [...parts];

        const sourceQuestions = [...newParts[dropSource].questions!];
        const destinationQuestions = dropSource === dropDestination
            ? sourceQuestions
            : [...newParts[dropDestination].questions!];

        const [moved] = sourceQuestions.splice(result.source.index, 1);
        destinationQuestions.splice(result.destination.index, 0, moved);

        // TODO: invalidate queries?
        formReorderQuestionsMutation.mutate({
            form: props.form,
            request: {
                sourcePart: newParts[dropSource].order_id,
                destinationPart: newParts[dropDestination].order_id,
                start: result.source.index,
                end: result.destination.index,
            }
        }, {
            onError: logError
        });

        newParts[dropSource].questions = sourceQuestions;
        newParts[dropDestination].questions = destinationQuestions;

        recalculateQuestionNumbers(newParts);

        setParts(newParts);
        recalculateBoxes();
    };

    const onPartDrop = (result: DropResult) => {
        if(!result.destination) {
            return;
        }

        const newParts = [...parts];

        const dropSource = result.source.index;
        const dropDestination = result.destination.index;

        formReorderPartsMutation.mutate({
            form: props.form,
            request: {
                start: dropSource,
                end: dropDestination,
            }
        }, {
            onError: logError
        });

        const [moved] = newParts.splice(dropSource, 1);
        newParts.splice(dropDestination, 0, moved);

        recalculateQuestionNumbers(newParts);

        setParts(newParts);
        recalculateBoxes();
    };

    const onConceptChange = (concept: boolean) => {
        setConcept(concept);
    };

    return (
        <React.Fragment>
            <Tabs noErrors scrollable>
                <TabBar>
                    <TabBarNavigation>
                        <div className={'flex flex--justify-between w-100 flex--align-center'}>
                            <div>
                                <Link to={route('admin-forms')} className={'tab-navigation__breadcrumbs'}>Formulieroverzicht / </Link>
                                <span className={'tab-navigation__breadcrumbs tab-navigation__breadcrumbs--active'}>{ props.form.name }</span>
                            </div>

                            <BackButton onClick={onBackClick} />
                        </div>
                    </TabBarNavigation>
                </TabBar>

                <TabContent>
                    <p className={'admin-form-detail__sub-title page__sub-title'} onClick={toggleQuestionModal}>Vragenlijst</p>
                    <h2 className={'admin-form-detail__title'}>{ props.form.name }</h2>

                    {/* <div className='mb-1'>
                        <Card>
                            <h2 className='mb-1'>Instellingen</h2>
                            <Checkbox onChange={onConceptChange} checked={concept} text='Concept' />
                        </Card>
                    </div> */}

                    <BoxList className={'admin-form-detail__box-list'} renderId={boxRenderId}>
                        <TabContentLeft>
                            <DragDropContext onDragEnd={onPartDrop}>
                                <Box>
                                    <BoxHeader>
                                        Fases
                                    </BoxHeader>

                                    <BoxContent>
                                        <ShortcutDroppable parts={parts}>
                                            <div className={'add-button__container'}>
                                                <AddButton onClick={() => openPartModal()}>Fase toevoegen</AddButton>
                                            </div>
                                        </ShortcutDroppable>
                                    </BoxContent>
                                </Box>
                            </DragDropContext>
                        </TabContentLeft>

                        <TabContentRight>
                            <DragDropContext onDragEnd={onQuestionDrop}>
                                { parts.map(part => (
                                    <Box key={part.id}>
                                        <BoxHeader>
                                            <div className={'flex flex--justify-between'}>
                                                <span>{ part.title }</span>

                                                <div className={'flex'}>
                                                    <span className={'edit-text admin-form-detail__edit-text'} onClick={() => onPartEditClick(part)}>Aanpassen</span>
                                                    <span className={'edit-text'} onClick={() => {
                                                        setSelectedPart(part)
                                                        toggleDeletePartModal()
                                                    }}>Verwijderen</span>
                                                </div>
                                            </div>
                                        </BoxHeader>

                                        <BoxContent>
                                            <Part
                                                part={part}
                                                onQuestionAddClick={onQuestionAddClick}
                                                onQuestionEdit={(question) => onQuestionEditClick(part, question)}
                                                toggleDeleteQuestionModal={toggleDeleteQuestionModal}
                                                setSelectedQuestion={setSelectedQuestion}/>
                                        </BoxContent>
                                    </Box>
                                )) }
                            </DragDropContext>
                        </TabContentRight>
                    </BoxList>
                </TabContent>
            </Tabs>

            <Modal className={'admin-form-detail__question-modal'} isOpen={questionModalOpen} active={questionModalActive} onCloseClick={toggleQuestionModal}>
                <QuestionModal
                    formId={props.form.id}
                    part={selectedPart}
                    question={selectedQuestion.question}
                    questionNumber={selectedQuestion.number}
                    onSave={onQuestionSave}
                    onCancel={toggleQuestionModal} />
            </Modal>

            <Modal className={'admin-form-detail__part-modal'} isOpen={partModalOpen} active={partModalActive} onCloseClick={togglePartModal}>
                <PartModal
                    part={selectedPart}
                    onSave={onPartSave}
                    onCancel={togglePartModal} />
            </Modal>

            <ConfirmModal isOpen={deletePartModalOpen} isActive={deletePartModalActive} onCloseClick={toggleDeletePartModal}
                          onDeleteConfirm={onPartDeleteClick} confirmObjectName={'fase'}/>

            <ConfirmModal isOpen={deleteQuestionModalOpen} isActive={deleteQuestionModalActive} onCloseClick={toggleDeleteQuestionModal}
                          onDeleteConfirm={onQuestionDeleteClick} confirmObjectName={'vraag'}/>
        </React.Fragment>
    );
};

export default FormDetailContent;
