import React, { FunctionComponent } from 'react';

import Center from './Center';
import CenterModal from '../../../../networking/models/Center';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    centers: any;
    onCenterClick?: (center: CenterModal) => void;
}

const CenterList: FunctionComponent<Props> = ({ centers, onCenterClick }) => {
    const { theme } = useTheme();

    return (
        <div className={`center-list center-list--${theme.modifier} ${onCenterClick ? 'center-list--big' : ''}`}>
            <div className={`center-list__header center-list__grid`}>
                <span className="center-list__title">Naam meldkamer</span>
                <span className="center-list__title">Stad</span>
                <span className="center-list__title">Contactpersoon</span>
                <span className="center-list__title">E-mail</span>
                <span className="center-list__title">Tel nummer</span>
                <span className="center-list__title">Sinds</span>
            </div>

            <div className="center-list__centers">
                {centers.map((center: any) => (
                    <Center center={center} key={center.id} onClick={onCenterClick} />
                ))}
            </div>
        </div>
    );
};

export default CenterList;
