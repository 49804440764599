import React, {FunctionComponent, useState} from 'react';

import Page from 'components/layout/Page';
import DashboardContent from './DashboardContent';
import {useDashboardQueryConfig} from '../../../query/Dashboard/Query';
import {useQuery} from '@tanstack/react-query';

const Dashboard: FunctionComponent = () => {

    const currentDate = `${new Date().toISOString().split('T')[0].substring(0, 7)}`;
    const [month, setMonth] = useState(currentDate);

    const [config] = useDashboardQueryConfig(month);
    const query = useQuery(config);

    const setMonthHandler = (month: string) => {
        if (isValidMonth(month)) setMonth(month);
    }

    const isValidMonth = (input: string) => {
        const [year, month] = input.split('-');
        return year.length === 4 && month.length === 2;
    }

    return (
        <Page scrollable>
            { query.data &&
                <DashboardContent data={query.data} month={month} setMonth={setMonthHandler} />
            }
        </Page>
    );
};

export default Dashboard;
