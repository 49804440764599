import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';

import {FormAccessor, input, select, useForm, Validation} from 'buro-lib-ts';

import SelectTab, {SelectTabData} from './SelectTab';
import AuditCallsTab from './AuditCallsTab';

import Audit from '../../../../../networking/models/Audit';
import AuditCall from '../../../../../networking/models/AuditCall';

import Button from '../../../../layout/buttons/Button';
import BackButton from '../../../../layout/buttons/BackButton';

import AuditRepository from '../../../../../networking/repos/AuditRepository';
import AuditCallRepository from '../../../../../networking/repos/AuditCallRepository';
import {useAuth} from "../../../../contexts/AuthContext";
import ModalBody from '../../modal/ModalBody';
import {success, warn} from '../../../../../helpers/Toast';
import {logError} from '../../../../utils/devtool/DevTool';
import {useTheme} from '../../../../contexts/ThemeContext';
import {validateNumber} from "../../../../../helpers/Validation";
import {useUserRoleAuditorQueryConfig, useUserRoleOperatorQueryConfig} from "../../../../query/User/Query";
import {useQuery} from "@tanstack/react-query";
import {useFormForCenterQueryConfig} from "../../../../query/Form/Form";

const validateCallCountInput = (value: string) => {
    if(value === '') return Validation.invalid(Validation.message('Waarde is verplicht!'));
    return validateNumber(value, 1, 8);
};

const validateRequiredCallsInput = (value: string) => {
    return validateNumber(value, 0, 8);
};

export type OnAuditCreatedCallback = () => void;

interface Props {
    onCancelClick: () => void;
    onAuditCreate: OnAuditCreatedCallback;

    audit?: Audit;
    auditCalls?: AuditCall[];
    className?: string;
}

// TODO: Fix
const AuditDataBox: FunctionComponent<Props> = (props) => {
    const {theme} = useTheme();
    const {user} = useAuth();

    const {onCancelClick, onAuditCreate} = props;

    const [tab, setTab] = useState<number>(0);

    const [audit, setAudit] = useState<Audit>();
    const [auditCalls, setAuditCalls] = useState<AuditCall[]>([]);
    const [addedAuditCalls, setAddedAuditCalls] = useState<AuditCall[]>(props.auditCalls ?? []);

    const [done, setDone] = useState<boolean>(false);

    const formConfig = useMemo(() => {
        return {
            auditorId: select<SelectTabData, number>(-1, null, []),
            callerId: select<SelectTabData, number>(-1, null, []),
            formId: select<SelectTabData, number>(-1, null, []),
            auditCallCount: input('', [validateCallCountInput]),
            requiredAuditCalls: input('', [validateRequiredCallsInput])
        };
    }, []);

    const [selectTabForm, onSelectTabFormChange] = useForm<SelectTabData>(formConfig);

    const auditRepository = new AuditRepository();

    const center = user?.center!;
    const [auditorsQueryConfig] = useUserRoleAuditorQueryConfig(center);
    const [operatorsQueryConfig] = useUserRoleOperatorQueryConfig(center);
    const [formQueryConfig] = useFormForCenterQueryConfig(center);
    const auditors = useQuery(auditorsQueryConfig).data;
    const operators = useQuery(operatorsQueryConfig).data;
    const forms = useQuery(formQueryConfig).data;

    useEffect(() => {
        const {audit, auditCalls} = props;

        if (audit && auditCalls) {
            selectTabForm.set({
                requiredAuditCalls: audit.required_calls.toString(),
                auditCallCount: auditCalls.length.toString(),
                auditorId: audit.auditor_id,
                callerId: audit.operator?.id,
                formId: audit.form_id
            });

            setAuditCalls(auditCalls);
            setAddedAuditCalls(auditCalls);
        }
    }, [props.audit, props.auditCalls, setAuditCalls, setAddedAuditCalls]);

    const selectTabActive = () => {
        return tab === 0;
    };

    const auditCallTabActive = () => {
        return tab === 1;
    };

    const saveAuditResources = async (form: FormAccessor<SelectTabData>) => {
        // TODO: Cast values to integer if needed
        if (form.values.auditCallCount === auditCalls.length && !props.audit) return; // TODO: Why?

        let auditResult: Audit | null;

        // Create/Update audit
        const requiredCalls = typeof form.values.requiredAuditCalls === 'string'
            ? 0
            : form.values.requiredAuditCalls;

        auditResult = await auditRepository.save({
            id: props.audit?.id ?? audit?.id,
            auditor_id: form.values.auditorId,
            operator_id: form.values.callerId,
            form_id: form.values.formId,
            required_calls: requiredCalls
        });

        if (!auditResult.id || !form.values.auditCallCount) return; // TODO: catch error

        const auditCallCount = +form.values.auditCallCount - (auditCalls.length);

        if (auditCallCount < 0) {
            await removeCalls(auditResult, Math.abs(auditCallCount));
        } else {
            await addCalls(auditResult, auditCallCount);
        }

        if (Math.abs(auditCallCount) > 0) {
            reopenAuditTab();
        }

        setAudit(auditResult);
    };

    const removeCalls = async (audit: Audit, amount: number) => {
        const auditCallRepository = new AuditCallRepository(audit.id!);

        const newState = [...auditCalls];
        const toRemove = newState.splice(newState.length - amount, amount);

        await auditCallRepository.destroyAll(toRemove);

        setAuditCalls(newState);

        const addedAuditCallsRemoveCount = addedAuditCalls.length - (auditCalls.length - amount);

        if (addedAuditCallsRemoveCount > 0) {
            const newAddedState = [...addedAuditCalls];
            newAddedState.splice(newAddedState.length - addedAuditCallsRemoveCount, addedAuditCallsRemoveCount);

            setAddedAuditCalls(newAddedState);
        }
    };

    const addCalls = async (audit: Audit, amount: number) => {
        const auditCallRepository = new AuditCallRepository(audit.id!);

        const auditCallResult = await auditCallRepository.saveAll(new Array(amount).fill({}), true);

        setAuditCalls([...auditCalls, ...auditCallResult]);
    };

    const nextTab = (form: FormAccessor<SelectTabData>) => {
        if(!form.isValid) return;
        saveAuditResources(form).then(() => {
            setTab(tab + 1);
        }).catch((e) => {
            resetModal();

            logError(e);
            e.message && warn(e.message);
        });
    };

    const previousTab = () => {
        setTab(tab - 1);
    };

    const resetModal = () => {
        const {audit, auditCalls} = props;

        if (!audit && !auditCalls) {
            setAudit(undefined);
            setAuditCalls([]);
            setAddedAuditCalls([]);
            setDone(false);
        }

        selectTabForm.set({
            requiredAuditCalls: audit?.required_calls.toString() ?? '',
            auditCallCount: auditCalls?.length.toString() ?? '0',
            auditorId: audit?.auditor_id,
            callerId: audit?.operator?.id,
            formId: audit?.form_id
        });

        setTab(0);
    };

    const cancel = async () => {
        if (audit?.id && !props.audit) {
            await auditRepository.destroy(audit).catch(logError);
        }

        resetModal();
        onCancelClick();
    };

    const onAuditTabFinish = () => {
        setDone(true);
    };

    const onAuditFinishCreate = () => {
        auditRepository.finishAuditCreate(audit!).catch(logError);
    };

    const onFinishClick = () => {
        onAuditFinishCreate();
        resetModal();
        onCancelClick();
        onAuditCreate();
    };

    const onFinish = () => {
        if (props.audit) {
            onCancelClick();
        } else {
            onFinishClick();
        }
    };

    const AuditCallTabControls = () => {
        const finishDisabled = props.audit
            ? false
            : (!done || addedAuditCalls.length === 0);

        return (
            <React.Fragment>
                <BackButton onClick={previousTab}>Terug</BackButton>
                <Button onClick={onFinish} disabled={finishDisabled}>Afronden</Button>
            </React.Fragment>
        );
    };

    const reopenAuditTab = () => {
        setDone(false);
    };

    const onAuditCallDelete = (auditCall: AuditCall) => {
        new AuditCallRepository(audit!.id!).destroy(auditCall).then(() => {
            removeAuditCall(auditCall);
            success('Gesprek is verwijderd.');
        }).catch((e) => {
            logError(e);
            warn('Er is een fout opgetreden.');
        });
    };

    const removeAuditCall = (auditCall: AuditCall) => {
        const index = addedAuditCalls.findIndex((value) => {
            return value.id === auditCall.id;
        });

        const newAddedAuditCalls = [...addedAuditCalls];
        newAddedAuditCalls.splice(index, 1);

        const newAuditCalls = [...auditCalls];
        newAuditCalls.splice(index, 1);

        setAddedAuditCalls(newAddedAuditCalls);
        setAuditCalls(newAuditCalls);

        if (newAuditCalls.length > 0) {
            selectTabForm.set({auditCallCount: newAuditCalls.length.toString()});
        } else {
            setTab(0);
        }
    }

    return (
        <ModalBody onCloseClick={cancel}>
            <div className={`audit-create-box create-audit-box--${theme.modifier}`}>
                <SelectTab
                    selectForm={selectTabForm}
                    onSelectFormChange={onSelectTabFormChange}
                    onNextClick={nextTab}
                    onCancelClick={cancel}
                    auditors={auditors}
                    triagists={operators}
                    forms={forms || []}
                    active={selectTabActive()}/>

                <AuditCallsTab
                    auditCalls={auditCalls}
                    setAuditCalls={setAuditCalls}
                    addedAuditCalls={addedAuditCalls}
                    setAddedAuditCalls={setAddedAuditCalls}
                    audit={audit}
                    onFinish={onAuditTabFinish}
                    active={auditCallTabActive()}
                    onAuditCallDelete={onAuditCallDelete}
                    done={done}/>

                <div className={'audit-create-box__controls'}>
                    {auditCallTabActive() &&
                        <AuditCallTabControls/>
                    }
                </div>
            </div>
        </ModalBody>
    );
};

export default AuditDataBox;
