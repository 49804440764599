import React, { FunctionComponent } from 'react';
import Question from '../../../../networking/models/Question';
import FormQuestion from '../../../client/partials/conversations/form/FormQuestion';

export interface Props {
    question: Question;
    onEdit: (question: Question) => void;
    toggleDeleteQuestionModal: () => void;
    setSelectedQuestion: Function
    partId: number | undefined
}

const PartQuestion: FunctionComponent<Props> = ({ question, onEdit, toggleDeleteQuestionModal, setSelectedQuestion, partId }) => {

    return (
        <FormQuestion question={question}>
            <div>
                <p className={'edit-text'} onClick={() => onEdit(question)}>Aanpassen</p>
                <p className={'edit-text'} onClick={() => {
                    const questionToDelete = {question: {...question, part_id: partId}, number: -1}
                    setSelectedQuestion(questionToDelete)
                    toggleDeleteQuestionModal()
                }}>Verwijderen</p>
            </div>
        </FormQuestion>
    );
};

export default PartQuestion;
