import React, {FunctionComponent} from 'react';
import {useParams} from 'react-router-dom';

import Page from '../../../../layout/Page';
import {useAuditTypeOperatorQueryConfig} from '../../../../query/Audit/Query';
import {useQuery} from '@tanstack/react-query';
import UserAuditsContent from './UserAuditsContent';
import {useUserIndexQuery, useUserShowQuery} from '../../../../query/User/Query';
import {useAuth} from '../../../../contexts/AuthContext';

const UserAudits: FunctionComponent = () => {
    const { user } = useAuth();
    const params = useParams();
    const userId = +params.id!;

    const [auditConfig] = useAuditTypeOperatorQueryConfig({
        operatorId: +params.id!,
    });

    const auditQuery = useQuery(auditConfig);
    const userQuery = useUserShowQuery(user!.center, userId);
    return (
        <Page className={'user-audits-page'}>
            { (auditQuery.data && userQuery.data) &&
                <UserAuditsContent
                    user={userQuery.data!}
                    audits={auditQuery.data.data} />
            }
        </Page>
    );
};

export default UserAudits;
