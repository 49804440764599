import React, {FunctionComponent} from "react";
import Box from "../../../partials/box/Box";
import BoxHeader from "../../../partials/box/BoxHeader";
import BoxContent from "../../../partials/box/BoxContent";
import Select from "../../../partials/input/select/Select";
import ScoresPerQuestion from "../../../../../networking/models/ScoresPerQuestion";
import Button from "../../../../layout/buttons/Button";
import {Doughnut} from "react-chartjs-2";
import {ScoreFilter} from "../../../../query/Center/Query";


interface Props {
    filters: ScoreFilter;
    graphDoughnutData: any;
    renderDoughnutGraph: boolean;
    scoresPerQuestion: ScoresPerQuestion[];
    onQuestionSelect: (question: ScoresPerQuestion) => void;
    getCriteriumScores: () => void;
}

const CriteriaScoresBox: FunctionComponent<Props> = ({filters, graphDoughnutData, renderDoughnutGraph, scoresPerQuestion, onQuestionSelect, getCriteriumScores}) => {

    const colorYes = '#2bda7e';
    const colorNo = '#d71e00';
    const colorNvt = '#f9dd4b';

    const graphDoughnutDataObject = {
        labels: ['Ja', 'Nee', 'N.v.t.'],
        datasets: [
            {
                label: 'Scores',
                backgroundColor: [
                    colorYes,
                    colorNo,
                    colorNvt
                ],
                hoverBackgroundColor: [
                    colorYes,
                    colorNo,
                    colorNvt
                ],
                data: [
                    Math.round(graphDoughnutData.yes * 100) / 100,
                    Math.round(graphDoughnutData.no * 100) / 100,
                    Math.round(graphDoughnutData.nvt * 100) / 100
                ]
            }
        ]
    }

    const graphDoughnutOptions = {
        animation: {
            duration: 0
        },
        plugins: {
            title: {
                display: renderDoughnutGraph,
                text: (renderDoughnutGraph) ? (`Scores voor criteria van formulier '${filters.form?.name}'`) : (''),
            }
        },
    }

    return (
        <Box>
            <BoxHeader>
                Gemiddelde scores per criterium per formulier
            </BoxHeader>
            <BoxContent>
                <div className="statistics-page__line statistics-page__row">
                    <div className="statistics-page__col--left">
                        <div className="statistics-page__filters">
                            <Select
                                title={'Kies criterium'}
                                options={scoresPerQuestion}
                                onSelect={onQuestionSelect}
                                renderOption={(score: ScoresPerQuestion) => (score.question)} />

                            <br/>
                            <Button onClick={getCriteriumScores}>Genereer diagram</Button>
                        </div>
                    </div>

                    <div className="statistics-page__col--right">
                        <div className="statistics-page__doughnut--wrapper">
                            <div className="statistics-page__doughnut--canvas">
                                {
                                    (renderDoughnutGraph) ? (
                                        <Doughnut data={graphDoughnutDataObject}
                                                  options={graphDoughnutOptions}/>
                                    ) : (
                                        <div className="statistics-page__placeholder">
                                            <p>Selecteer een criterium</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </BoxContent>
        </Box>
    )
}

export default CriteriaScoresBox;
