import React, {FunctionComponent } from 'react';

import Score from 'networking/models/Score';

interface Props {
    scores: Score[];
}

const ResultsBox: FunctionComponent<Props> = ({ scores }) => {

    return (
        <div className={'results-box'}>
          <div className={'results-box__content'}>
              <div className={'results-box--card'}>
                  <div className={'flex flex--justify-between flex--align-center'}>
                        <h3 className={'results-box__score-text'}>Totaalscore per onderdeel</h3>
                  </div>

                  <div className={'results-box__score'}>
                      <div className={'results-box__score-top'} />
                      <span className={'results-box__score-top'}>Triagist:</span>
                      <span className={'results-box__score-top'}>Meldkamer:</span>
                  </div>
                    {scores.slice(0).reverse().map((scoreItem: Score, index: number) => (
                        <div className={'results-box__score'} key={index}>
                            <div className={`results-box__score-block results-box__score-block--${scoreItem.type}`} />
                            <span className={'results-box__score-text'}>{scoreItem.score}% voldaan</span>
                            <span className={'results-box__score-text'}>{scoreItem.norm}% voldaan</span>
                        </div>
                    ))}
              </div>
          </div>
        </div>
    );
};

export default ResultsBox;
